import axios from 'axios';
import { set } from 'lodash';
import React from 'react'
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';

const ButtonMessageBottom = () => {

    const [config, setConfig] = React.useState({
        show: false,
        message: '',
        loading: false,
    });

    const handleButton = async () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Se enviará una notificación de ayuda a recepción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // await saveMessage();
                await fetch('/admin/notifications/save', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                }).then(res => res.json())
                .then(data => {
                    setConfig({
                        ...config,
                        loading: false,
                        show: false,
                        message: '',
                    });
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensaje enviado',
                        text: 'El mensaje se envió correctamente',
                    });
                }).catch(err => {
                    console.log(err);
                    setConfig({
                        ...config,
                        loading: false,
                    });
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ocurrió un error al enviar el mensaje',
                    });
                });
            }
        });
    }

    const handleMessage = (e) => {
        setConfig({
            ...config,
            message: e.target.value,
        })
    }

    const toggleShow = () => {
        setConfig({
            ...config,
            show: !config.show,
        })
    }

    return (
        <div>
            {/* {config.show &&
                <div className='text-center wrap-message-bottom'>
                    <h2>¿Necesitas ayuda?</h2>
                    <textarea 
                        value={config.message} 
                        onChange={handleMessage} 
                        className='message-bottom form-control mb-2' 
                        rows={4} 
                        placeholder='Escribe un mensaje...'>
                        </textarea>
                    {config.loading &&
                        <div className="spinner-border text-secundary" role="status" style={{float: 'right'}}>
                            <span className="visually-hidden"></span>
                        </div>
                    }
                    {!config.loading &&
                        <div>
                            <button onClick={toggleShow} style={{marginLeft: 10}}>Cancelar</button>
                            <button onClick={handleButton}>Solicitar Ayudar Ahora</button>
                        </div>
                    }
                </div>
            } */}
            {!config.show &&
                <button onClick={handleButton} className="globe-message">Solicitar Ayuda</button>
            }
        </div>
    )
}

export default ButtonMessageBottom

if (document.getElementById('buttonMessageBottom')) {
    ReactDOM.render(<ButtonMessageBottom />, document.getElementById('buttonMessageBottom'));
}