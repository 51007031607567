/**
 * Front desk JS
 *
 */

import Swal from 'sweetalert2';

window.frontDesk = new (function() {

    const that = this;
    var roomToChangeSelected = '';

    this.init = function (){

        //$('.dash-home').shapeshift();
        //$('.dash-home').sortable();
        //$('.dash-home').on( "sortstop", that.getNewRoom );

        //show change room
        $('.change-room').on('click', that.showRoomsAvailable);

        //new room selected
        $('.item_empty a').on('click', that.saveNewRoom);

        //detect close submenu
        $('.dash-options_menu').on('click', that.checkCloseSubmenu);

        //show form for change stay date
        $('.change-stay').on('click', that.showCalendars);
        //close change stay date swal
        $(document).on('click', '.wrap-change-stay-date .main-content .head .close, .wrap-change-stay-date .main-content .wrap-buttons .cancel', function (){
            Swal.close();
        });
        $(document).on('click', '.wrap-change-stay-date .main-content .wrap-buttons .update-stay-date', that.saveNewStayDate);

        //view Profile
        $('.view-profile').on('click', that.showPatientProfile);

        //disable room
        $('.disableRoom').on('click', that.disableRoomConfirm);

        $('.dropdownMenuButton').on('click', function(event) {
            event.preventDefault(); // Evitar el comportamiento por defecto del enlace
            $(this).next('.dropdown-menu').toggle(); // Alternar la visibilidad del menú
        });

        // Cerrar el dropdown si se hace clic fuera de él
        $(document).on('click', function(event) {
            if (!$(event.target).closest('.dropdown').length) {
                $('.dropdown-menu').hide(); // Ocultar el menú si se hace clic fuera
            }
        });

        //
        $('.medical-assessment-authorize').on('click', that.medicalAssessmentAuthorize);
    };

    /**
     * Show alert
     * @param title
     * @param text
     * @param cancelBtn
     * @param icon
     */
    this.showAlert = function (title, text, cancelBtn, icon = '') {

        Swal.fire({
            title: title,
            icon: icon,
            html: text,
            showCloseButton: true,
            showCancelButton: cancelBtn,
            focusConfirm: false,
        });

    };

    /**
     * show the available rooms
     */
    this.showRoomsAvailable = function () {
        var changeExist = $('#content .backForRemarkHead');

        if(changeExist.length > 0) {
            that.cancelRoomChange();
            $(this).parent().removeClass('show');
            return;
        }

        var heightT = $(document).height();
        $(this).html('Cancelar cambio de habitación');
        $(this).removeClass('change-room');
        $(this).addClass('cancel-change-room');
        $('.item_empty').parent().attr("style", "z-index:2;");
        $('.item_empty a').attr("href", "javascript:;");
        $('.item_empty a').addClass("room-has-been-selected");
        $('.item_empty a .item_empty_add').html("seleccione habitación");
        $('#content').prepend('<div class="backForRemarkHead">Seleccione la habitación vacía deseada</div><div class="backForRemark" style="min-height:' + heightT + 'px"></div>');

        roomToChangeSelected = $(this).data('residence-id');
    };

    /**
     * cancel room change
     */
    this.cancelRoomChange = function () {
        $('#content .backForRemarkHead').remove();
        $('#content .backForRemark').remove();
        $(this).addClass('change-room');
        $(this).removeClass('cancel-change-room');
        $('.item_empty').parent().attr("style", "");
        $('.item_empty a').removeClass("room-has-been-selected");

        $.each($('.item_empty a'), function (index, value){
            var url = $(value).data('url');
            $(value).attr("href", url);
        });

        $('.item_empty a .item_empty_add').html( plusAddPatientHTML + "Nuevo paciente");
    };

    /**
     * Save new room
     */
    this.saveNewRoom = function () {

        if( !$(this).hasClass('room-has-been-selected') ) {
            return;
        }
        var roomSelected = $(this).data('room-id');

        //ajax settings
        var settings = {
            url: patientChangeOfRoomRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'residence_id': roomToChangeSelected,
                'room_id': roomSelected,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {

            if(response == 'ok') {
                Swal.fire({
                    title: 'Listo!',
                    text: 'La nueva habitación ha sido asignada al paciente.',
                    icon: 'success',
                    showCloseButton: false,
                    showConfirmButton:false
                });

                setTimeout(function (){
                    location.reload();
                }, 2000);
            } else {
                //room not found
                Swal.fire(
                    'Habitación no encontrada',
                    'Por favor, verifica que la habitación seleccionada esté disponible',
                    'error'
                );
            }

        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrió un error',
                error,
                'error'
            );
        });

    };

    /**
     * check if the submenu is closed
     */
    this.checkCloseSubmenu = function () {
        if(!$(this).hasClass('show')) {
            that.cancelRoomChange();
        }
    };

    /**
     * Show calendar form
     */
    this.showCalendars = function () {
        var currentCheckInDate = $(this).data('checkin');
        var currentCheckOutDate = $(this).data('checkout');
        var residenceID = $(this).data('residence-id');

        var inFormattedDate = new Date(currentCheckInDate);
        var ind = inFormattedDate.getDate().toString().padStart(2, '0');
        var inm =  (inFormattedDate.getMonth() + 1).toString().padStart(2, '0');

        var iny = inFormattedDate.getFullYear();

        var outFormattedDate = new Date(currentCheckOutDate);
        var outd = outFormattedDate.getDate().toString().padStart(2, '0');
        var outm =  (outFormattedDate.getMonth() + 1).toString().padStart(2, '0');

        var outy = outFormattedDate.getFullYear();

        console.log(iny + '-' + inm + '-' + ind);

        Swal.fire({
            html: changeStayDateHTML,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: 'swal-change-stay-dates',
            didOpen: () => {
                $(document).find('.wrap-change-stay-date .main-content .body #check-in-date').val(iny + '-' + inm + '-' + ind).prop('disabled', true);
                $(document).find('.wrap-change-stay-date .main-content .body #check-out-date').val(outy + '-' + outm + '-' + outd);
                $(document).find('.wrap-change-stay-date .main-content .body #residence-id-date').val(residenceID);
            }
        });

    };

    /**
     * Save the new stay date
     */
    this.saveNewStayDate = function () {
        var newCheckIn = $(document).find('.wrap-change-stay-date .main-content .body #check-in-date').val();
        var newCheckOut = $(document).find('.wrap-change-stay-date .main-content .body #check-out-date').val();
        var residenceID = $(document).find('.wrap-change-stay-date .main-content .body #residence-id-date').val();
        $(document).find('.wrap-change-stay-date .main-content .wrap-errors .error').removeClass('d-block').addClass('d-none').html('');
        var checkInDate = new Date(newCheckIn);
        var checkOutDate = new Date(newCheckOut);
        var currentDate = new Date();
        var currd = currentDate.getDate().toString().padStart(2, '0');
        var currm =  (currentDate.getMonth() + 1).toString().padStart(2, '0');

        var curry = currentDate.getFullYear();
        var currentDateWithFormat = curry + '-' + currm + '-' + currd;

        if (newCheckIn >= newCheckOut) {
            //check-out date is older than check-in date
            var errorDisplay = $(document).find('.wrap-change-stay-date .main-content .wrap-errors .error');

            $(errorDisplay).removeClass('d-none').addClass('d-block').html('Por favor, verifique las fechas. La fecha de salida no puede ser menor al de la fecha de entrada.');
            return false;
        }
        if(newCheckOut <= currentDateWithFormat) {
            //check-out date is older than current date
            var errorDisplay = $(document).find('.wrap-change-stay-date .main-content .wrap-errors .error');

            $(errorDisplay).removeClass('d-none').addClass('d-block').html('Por favor, verifique las fechas. La fecha de salida no puede ser menor al día de hoy.');
            return false;
        }

        //ajax settings
        var settings = {
            url: saveNewStayDateRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'residence_id': residenceID,
                'check_in': newCheckIn,
                'check_out': newCheckOut,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {

            if(response == 'error') {
                Swal.fire({
                    title: 'Alerta!',
                    text: 'Residencia no encontrada',
                    icon: 'warning',
                    showCloseButton: false,
                    showConfirmButton:false
                });
            } else {
                Swal.fire({
                    title: 'Listo!',
                    text: 'El cambio de fecha ha quedado efectuado.',
                    icon: 'success',
                    showCloseButton: false,
                    showConfirmButton:false
                });

                setTimeout(function (){
                    location.reload();
                }, 2000);
            }



        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrió un error',
                error,
                'error'
            );
        });
    };

    /**
     * Show patient profile
     */
    this.showPatientProfile = function () {
        let imgProfile = $(this).find('img');
        let profile = $(this).data('profile');

        Swal.fire({
            html: patientProfileHTML,
            showCancelButton: false,
            showConfirmButton: true,
            customClass: 'swal-patient-profile',
            didOpen: () => {
                $(document).find('.wrap-profile .img-thumbnail').attr('src', $(imgProfile).attr('src'));
                $(document).find('.wrap-profile .nickname').html(profile.patient.user.nickname);
                $(document).find('.wrap-profile .patient-name').html(profile.patient.user.name + ' ' + profile.patient.user.last_name);
                $(document).find('.wrap-profile .patient-birthday').html(profile.patient.birthdate);
                $(document).find('.wrap-profile .patient-email').html(profile.patient.user.email);
                $(document).find('.wrap-profile .patient-address').html(profile.patient.address);
                $(document).find('.wrap-profile .patient-phone').html(profile.patient.phone);
                $(document).find('.wrap-profile .patient-city').html(profile.patient.city);
            }
        });
    };

    /**
     * Check open alerts
     * and close it
     */
    this.checkForAlerts = function () {
        let openAlerts = $(document).find('.alert');

        if( openAlerts.length > 0 ) {
            setTimeout(function (){
                $(openAlerts).remove();
            }, 8000);
        }
    };

    /**
     * Disable room confirmation
     */
    this.disableRoomConfirm = function () {
        let roomSelected = $(this).data('roomid');
        Swal.fire({
            title: '',
            text: '¿Estás seguro que deseas deshabilitar la habitación?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton:true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                that.disableRoom(roomSelected);
            }
        });
    }

        /**
     * Disable room
     */
    this.disableRoom = function (roomSelected) {

        //ajax settings
        var settings = {
            url: disableRoomRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'room_id': roomSelected,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {

            if(response == 'ok') {
                Swal.fire({
                    title: 'Listo!',
                    text: 'La habitación ha sido deshabilidata.',
                    icon: 'success',
                    showCloseButton: false,
                    showConfirmButton:false
                });

                setTimeout(function (){
                    location.reload();
                }, 2000);
            } else {
                //room not found
                Swal.fire(
                    'La habitación no se puede deshabilitar',
                    'Por favor, compruebe que la habitación no cuente con reservación o adeudo',
                    'error'
                );
            }

        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrió un error',
                error,
                'error'
            );
        });

    };

    /**
     * Medical assessment authorize
     */
    this.medicalAssessmentAuthorize = function () {
        let residenceID = $(this).data('residenceid');
        let title = 'Autorización para Valoración Médica';
        let icon = 'warning';
        let html = '<div class="wrap-authorize"><label class="label-primary d-block">Ingrese contraseña</label><input type="password" class="text-center" name="authorize-password" id="authorize-password"/></div>';

        Swal.fire({
            title: title,
            html: html,
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton:true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // Obtener la contraseña ingresada
                let password = $('#authorize-password').val();

                // Realizar la petición AJAX
                $.ajax({
                    url: medicalAssessmentAuthorizeRoute,  // Actualiza esta ruta según tu configuración
                    type: 'POST',
                    data: {
                        password: password,
                        residence_id: residenceID,
                        _token: $('meta[name="csrf-token"]').attr('content') // Asegura de enviar el token CSRF si estás usando Laravel
                    },
                    success: function(response) {
                        if (response.valid) {
                            Swal.fire({
                                icon: 'success',
                                title: response.message,
                                text: '',
                            });
                            // Aquí puedes continuar con la acción necesaria si la contraseña es correcta
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Contraseña Incorrecta',
                                text: response.message,
                            });
                        }
                    },
                    error: function() {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Ocurrió un error al validar la contraseña. Por favor, intente de nuevo más tarde.',
                        });
                    }
                });
            }
        });
    };

})();
