/**
 * Patient JS
 *
 */

import Swal from "sweetalert2";
import "slick-carousel";

window.Patient = new (function () {
    const that = this;
    const month = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    var timer;
    var delay = 800;

    /**
     * Initialize stuff
     */
    this.init = function () {
        //tooltips
        try {
            $(".tooltip").tooltip();
        } catch (e) {}

        //check room availability
        that.checkAvailabilityRoomSelected();

        //check for date crossing
        $("#check-in").on("change", that.checkForDateCrossing);
        //check for date crossing
        $("#check-out").on("change", that.checkForDateCrossing);

        //show futures reservations
        $(".future-reservation").on("click", that.showFuturesReservations);

        //room change and check availability
        $("#room_id").on("change", that.checkAvailabilityRoomSelected);

        //webcam listener
        that.webcamListener();

        //detect date change
        $(".date-type").on("change", that.calculateResidenceCost);

        //detect file loaded in input
        //$("#patient-img").on("change", that.showImgSelected);
        $(".image-container").on("change", that.showImgSelected);

        //save patient info
        $(".save-patient").on("click", that.validatePatientForm);

        //search patient
        $(".search-patient").on("click", that.showSearchPatient);

        //type search action
        $(".typeSearch").on("keyup", that.filterSearchPatient);

        //patient selected
        $(".patient-list .item").on("click", that.autoFillFields);

        try {
            //days residence slider
            $('.residence-days-slick').slick({
                slidesToShow: 10,
                slidesToScroll: 3,
                arrow: true,
                infinite: false,
            });
        } catch (e) {}

        //detect day change
        $(".day-change").on("click", function () {
            var day = $(this).data("day");
            $(".current-day").val(day);
            $(".therapies-days").removeClass("show");
            $(".wrap-therapies-content-day-" + day).addClass("show");
        });

        //copy menu
        $(".copy-same-that-before").on("click", that.copyMenu);

        //Add therapies
        //$('#therapies').on('change', that.addTherapies);
        $(".add-product").on("click", that.addProduct);
        $(".delete-product-in-basket").on(
            "click",
            that.deleteProductFromBasket
        );
        $(".add-therapy").on("click", that.addTherapies);

        $(document).on("click", ".delete-therapy", that.deleteSelectedTherapy);

        //Add product or service
        $(".add-product-service").on("click", that.addProductService);

        //close add product or service swal
        $(document).on(
            "click",
            ".wrap-add-product-service .main-content .head .close, " +
                ".wrap-add-product-service .main-content .wrap-buttons .cancel, " +
                ".wrap-change-stay-date .main-content .head .close, " +
                ".wrap-payment .main-content .wrap-buttons .cancel, " +
                ".wrap-payment .main-content .head .close",
            function () {
                Swal.close();
            }
        );

        //show search product or service
        $(document).on(
            "click",
            ".wrap-add-product-service .main-content .search-patient",
            that.showSearchProductService
        );
        //search product or service
        $(document).on(
            "keyup",
            ".wrap-add-product-service .main-content .wrap-input .typeSearch",
            that.searchProductService
        );
        //product or service has been selected from search
        $(document).on(
            "click",
            ".wrap-add-product-service .main-content .product-service-selected",
            that.addSelectedProductService
        );
        //save product or service added
        $(document).on(
            "click",
            ".wrap-add-product-service .main-content .wrap-buttons .add-item",
            that.saveAddProductService
        );
        //remove item
        $(".remove-item").on("click", that.removeProductService);

        //Payment
        $(".showPayment").on("click", that.showPaymentWindow);
        $(document).on(
            "change",
            ".wrap-payment .main-content .body select",
            that.toggleIDFields
        );
        $(document).on(
            "click",
            ".wrap-payment .main-content .body #partial_payment",
            that.togglePartialPaymentFields
        );
        $(document).on(
            "click",
            ".wrap-payment .main-content .wrap-buttons .do-payment",
            that.doThePayment
        );
        //product to pay
        $(document).on(
            "click",
            ".wrap-payment .main-content .body .product-to-pay",
            that.togglePriceProductToPay
        );
        //therapy to pay
        $(document).on(
            "click",
            ".wrap-payment .main-content .body .therapy-to-pay",
            that.togglePriceTherapyToPay
        );

        //Discounts
        $(".add-discount").on("click", that.showAddDiscount);
        $(document).on(
            "keyup",
            "#html-main-wrap-room-discount.wrap-payment .main-content .body #amount_discount",
            that.calculateTheDiscount
        );
        $(document).on(
            "click",
            "#html-main-wrap-room-discount.wrap-payment .main-content .wrap-buttons .do-add-discount",
            that.doTheDiscount
        );

        //Product/Service Discounts
        $(".add-product-discount").on("click", that.showAddProductDiscount);
        $(document).on(
            "keyup",
            "#html-main-wrap-product-discount.wrap-payment .main-content .body #amount_discount",
            that.calculateTheProductDiscount
        );
        $(document).on(
            "click",
            "#html-main-wrap-product-discount.wrap-payment .main-content .wrap-buttons .do-add-product-discount",
            that.doTheProductDiscount
        );

        //Total Account Discounts
        $(".add-total-accout-discount").on("click", that.showAddTotalAccountDiscount);

        $(document).on(
            "keyup",
            "#html-main-wrap-total-account-discount.wrap-payment .main-content .body #amount_discount",
            that.calculateTheAccountDiscount
        );

        $(document).on(
            "click",
            "#html-main-wrap-total-account-discount.wrap-payment .main-content .wrap-buttons .do-add-account-discount",
            that.doTheAccountDiscount
        );

        //add eyes patient images
        $(".add-images").on("click", that.uploadEyesImages);

        //enter coupon code
        $(document).on("keyup", "#coupon_code", that.searchCoupon);

        //No hosting need it
        $("#no_hosting").on("change", that.noHostingNeedIt);

        //change reservation room
        $(document).on(
            "click",
            ".wrap-change-stay-date .change-room",
            that.showChangeRoomForm
        );
        $(document).on(
            "click",
            ".wrap-change-stay-date .save-change-room",
            that.saveChangeRoom
        );

        //change reservation date
        $(document).on(
            "click",
            ".wrap-change-stay-date .change-reservation",
            that.showChangeReservation
        );
        $(document).on(
            "click",
            ".wrap-change-stay-date .save-change-reservation",
            that.saveChangeReservation
        );

        //Input pressure
        $(".pressure").on("keyup", function () {
            $(this).val($(this).val().replace(" ", "/"));
        });

        //show big image
        $(".big-image").on("click", function () {
            let imageURL = $(this).data("image");
            Swal.fire({
                title: "",
                html: `<img src="` + imageURL + `">`,
            });
        });

        //save new room
        $(document).on("click", '.save-new-room', that.saveNewRoom);

        //Open Eyes images
        $('.image-thumbnail').on("click", function () {
            let imageURL = $(this).data("image");

            // Open thumbnail
            Swal.fire({
                width: '1100px',
                imageUrl: imageURL,
                imageWidth: 1000,
                imageAlt: ''
            });
        });

    };
    //init

    /**
     * Check room availability
     */
    this.checkAvailabilityRoomSelected = function () {
        var roomSelected = $("#room_id option:selected").val();

        if (typeof checkRoomAvailabilityRoute == 'undefined' || checkRoomAvailabilityRoute == "") {
            return;
        }

        if (roomSelected == 'nueva-habitacion') {
            //new room
            Swal.fire({
                html: newRoomHTML,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false,
                customClass: "swal-add-product-service",
            });
            return;
        }

        //ajax settings
        var settings = {
            url: checkRoomAvailabilityRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                room_id: roomSelected,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                dailyCostForResidence = response.roomSelected
                    ? response.roomSelected.price
                    : 0;

                that.calculateResidenceCost();

                if ($.isEmptyObject(response.occupied)) {
                    $("#room_id").removeClass("error");
                    $(".future-reservation").removeClass("d-block");
                    if (response.roomSelected) {
                        $(".future-reservation")
                            .addClass("d-none")
                            .data("roomselected", response.roomSelected.id);
                    }
                } else if (
                    response.occupied &&
                    !response.occupied.date_in_range
                ) {
                    $("#room_id").removeClass("error");
                    $(".future-reservation").removeClass("d-block");
                    if (response.roomSelected) {
                        $(".future-reservation")
                            .addClass("d-none")
                            .data("roomselected", response.roomSelected.id);
                    }
                } else {
                    $("#room_id").addClass("error");
                    if (response.roomSelected) {
                        $(".future-reservation")
                            .removeClass("d-none")
                            .data("roomselected", response.roomSelected.id);
                    }
                    //room not available
                    Swal.fire(
                        "Habitación ocupada",
                        "La habitación seleccionada no está disponible en este momento o está próxima a ocuparse. Por favor, verifique las fechas o seleccione otra habitación.",
                        "warning"
                    );
                }
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     *
     * Save new room
     * @param {*} e
     * @returns
     */
    this.saveNewRoom = function (e) {
        e.preventDefault();
        let roomName = $("#new-room-name").val();
        let roomPrice = $("#new-room-price").val();

        if (!roomName || !roomPrice) {
            Swal.fire(
                "Todos los campos son obligatorios",
                "Por favor, complete todos los campos para continuar",
                "warning"
            );
            return false;
        }

        //ajax settings
        var settings = {
            url: saveNewRoomRoute,
            method: "POST",
            data: {
                _token: $(document)
                   .find('meta[name="csrf-token"]')
                   .attr("content"),
                name: roomName,
                price: roomPrice,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                //refresh room list
                $('#room_id').empty();
                var itemSelected = '';
                //add data
                $.each(response, function(index, item) {
                    if (item.name == roomName) {
                        console.log(item.name, roomName);
                        itemSelected = 'selected';
                    }
                    $('#room_id').append('<option value="' + item.id + '" '+itemSelected+'>Habitación ' + item.name + '</option>');
                    itemSelected = '';
                });

                Swal.close();
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });

    }

    /**
     * Validate patient form
     */
    this.validatePatientForm = function (e) {
        var checkInDate = new Date($('#patient-form').find('#check-in').val());
        checkInDate.setHours(0, 0, 0, 0);
        var checkInDateAdjustment = checkInDate.setDate(checkInDate.getDate() + 1);
        var checkOutDate = new Date($('#patient-form').find('#check-out').val());
        var checkOutDateAdjustment = checkOutDate.setDate(checkOutDate.getDate() + 1);
        var currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        var currentDateAdjustment = currentDate.setDate(currentDate.getDate());
        var validationPassed = true;
        let noHosting = $("#no_hosting").is(":checked");
        let isReservation = $("#is_reservation").is(":checked");

        //validate if no hosting is needed
        if(!noHosting) {//in this case the hosting is required

            if ( (checkInDateAdjustment < currentDateAdjustment) || checkInDate == 'Invalid Date') {

                //check-in date is older than current date
                frontDesk.showAlert(
                    "Fecha no válida",
                    "La fecha de entrada tiene que ser mayor al día de hoy",
                    false
                );
                $("#patient-form").find("#check-in").addClass("error");
                return false;
            }

            if (
                new Date(checkInDateAdjustment) >
                    new Date(checkOutDateAdjustment) ||
                checkOutDate == "Invalid Date"
            ) {
                //check-out date is older than check-in date
                frontDesk.showAlert(
                    "Fecha no válida",
                    "Por favor, verifique la fecha de entrada y salida",
                    false
                );
                $("#patient-form").find("#check-out").addClass("error");
                return false;
            }

            //clean inputs from error class
            $("#patient-form").find("input").removeClass("error");

            //check required inputs
            if(!isReservation) {
                $.each($("#patient-form").find("input"), function (index, value) {
                    //get required attribute
                    var hasAttr = $(value).attr("required");

                    //check if has the attribute and if is empty
                    if (typeof hasAttr !== "undefined" && $(value).val() == "") {
                        $(value).addClass("error");
                        validationPassed = false;
                    }
                });
            }

        }

        if (validationPassed) {
            $("#patient-form").submit();
        } else {
            Swal.fire("", "Por favor, verifica los campos en rojo.", "warning");
        }
    };

    /**
     * load the image selected
     */
    this.showImgSelected = function () {
        let partenContainer = $(this).data('parent');
        var files = $(this).get(0).files[0];

        //file exist
        if (files) {
            var reader = new FileReader();

            //load src
            reader.onload = function (e) {
                $("#"+ partenContainer +".wrap-img .img-thumbnail").attr("src", e.target.result);
            };

            reader.readAsDataURL(files); // convert to base64 string
        }
    };

    /**
     * show/hide search patient
     */
    this.showSearchPatient = function () {
        $(".wrap-search-patient").toggleClass("d-none");
        $(this).toggleClass("open");
    };

    /**
     * filter patient list
     */
    this.filterSearchPatient = function (event) {
        var searchText = $(this).val();

        if (event.keyCode === 13) {
            event.preventDefault();
        }

        $.each($(".patient-list .item"), function (index, value) {
            var str = $(value).data("name");

            if (str.toLowerCase().indexOf(searchText) >= 0) {
                $(this).removeClass("d-none");
            } else {
                $(this).addClass("d-none");
            }
        });
    };

    /**
     * Autos fill form fields when the patient is clicked
     */
    this.autoFillFields = function () {
        $(".search-patient").trigger("click");

        $("#patient-form").find("#patient_id").val($(this).data("id"));
        $("#patient-form").find("#name").val($(this).data("name"));
        $("#patient-form").find("#last_name").val($(this).data("lastname"));
        $("#patient-form").find("#address").val($(this).data("address"));
        $("#patient-form").find("#city").val($(this).data("city"));
        $("#patient-form").find("#state").val($(this).data("state"));
        $("#patient-form")
            .find(".img-thumbnail")
            .attr("src", $(this).data("image"));
        $("#patient-form").find("#user_id").val($(this).data("userid"));
        $("#patient-form").find("#email").val($(this).data("email"));
        $("#patient-form").find("#nickname").val($(this).data("nickname"));
        $("#patient-form").find("#birthdate").val($(this).data("birthdate"));
    };

    /**
     * copy menu of a day before
     */
    this.copyMenu = function () {
        var currentDay = parseInt( $(".current-day").val() );
        var dayNext = currentDay + 1;
        var dayBefore = dayNext - 1;

        //console.log(currentDay, dayBefore, dayNext);

        $('.goToDay'+dayNext).trigger('click');

        $('.breakfast_day_' + dayBefore + ':checked').each(function() {
            var theBreakfastValue = $(this).val();
            var elementToCheck = $('.breakfast_day_'+ dayNext + '.bf_' + theBreakfastValue);
            //console.log('.breakfast_day_'+ dayNext + '.bf_' + theBreakfastValue);
            //console.log($(elementToCheck));
            $(elementToCheck).prop('checked', true);
        });

        $('.lunch_day_' + dayBefore + ':checked').each(function() {
            var theLunchValue = $(this).val();
            var elementToCheck = $('.lunch_day_'+ dayNext + '.ln_' + theLunchValue);
            $(elementToCheck).prop('checked', true);
        });

        $('.dinner_day_' + dayBefore + ':checked').each(function() {
            var theDinnerValue = $(this).val();
            var elementToCheck = $('.dinner_day_'+ dayNext + '.dn_' + theDinnerValue);
            $(elementToCheck).prop('checked', true);
        });

        $('.wrap-therapies-content-day-' + dayBefore + ' .therapies_for_select').each(function (){
            //console.log(currentDay, dayBefore, dayNext);
            //console.log($(this));
            if( $(this).is(':checked') ) {
                var theTherapyValue = $(this).val();
                var elementToCheck = $('.therapies_'+ dayNext+'_'+theTherapyValue);
                $(elementToCheck).prop('checked', true);
            }

        });
        /*
        $('.wrap-therapies-content-day-' + dayBefore + ' .therapies_extra_for_select').each(function (){
            //console.log(currentDay, dayBefore, dayNext);
            //console.log($(this));
            if( $(this).is(':checked') ) {
                var theTherapyValue = $(this).val();
                var elementToCheck = $('.therapies_extra_'+ dayNext+'_'+theTherapyValue);
                $(elementToCheck).prop('checked', true);
            }
        });
         */

        var therapyComment = $('.therapy_comment_'+ dayBefore).val();
        $('#therapy_comment_'+ dayNext).val(therapyComment);
        var therapyExtraComment = $('.therapy_extra_comment_'+ dayBefore).val();
        $('#therapy_extra_comment_'+ dayNext).val(therapyExtraComment);

        //$("#breakfast_day_" + currentDay).val(breakfast);
        //$("#lunch_day_" + currentDay).val(lunch);
        //$("#dinner_day_" + currentDay).val(dinner);
    };

    /**
     * add product
     */
    this.addProduct = function () {
        var productID = $("#products option:selected").val();
        var productText = $("#products option:selected").text();
        var productComment = $("#product_comment").val();
        var newElement = "";

        if (productID == 0 || productID == "") return;

        newElement += '<span id="product-selected-' + productID + '">';
        newElement += productText;
        newElement +=
            '<button type="button" class="delete-therapy" data-delete="' +
            productID +
            '"></button>';
        newElement +=
            '<input type="hidden" value="' +
            productID +
            '" name="products_in_basket[]" />';
        newElement +=
            '<input type="hidden" value="' +
            productComment +
            '" name="products_in_basket_comments[]" />';
        newElement += "</span>";

        $(".wrap-products-content").prepend(newElement);

        $("#product_comment").val("");
    };

    /**
     * Delete product from basket
     */
    this.deleteProductFromBasket = function () {
        let basketID = $(this).data("basketid");
        let theProduct = $(this);

        var settings = {
            url: deleteProductFromBasketRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                basket_id: basketID,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                $(theProduct).parent().remove();
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * add therapies
     */
    this.addTherapies = function () {
        var therapyID = $("#therapies option:selected").val();
        var therapyText = $("#therapies option:selected").text();
        var therapyComment = $("#therapy_comment").val();
        var currentDay = $(".current-day").val();
        var newElement = "";

        if (therapyID == 0 || therapyID == "") return;

        newElement += '<span id="therapy-selected-' + therapyID + '">';
        newElement += therapyText;
        newElement +=
            '<button type="button" class="delete-therapy" data-delete="' +
            therapyID +
            '"></button>';
        newElement +=
            '<input type="hidden" value="' +
            therapyID +
            '" name="day_' +
            currentDay +
            '_therapies[]" />';
        newElement +=
            '<input type="hidden" value="' +
            therapyComment +
            '" name="day_' +
            currentDay +
            '_therapy_comments[]" />';
        newElement += "</span>";

        $(".wrap-therapies-content-day-" + currentDay).prepend(newElement);

        $("#therapy_comment").val("");
    };

    /**
     * delete therapy from list
     */
    this.deleteSelectedTherapy = function () {
        var therapyID = $(this).data("delete");

        $("#therapy-selected-" + therapyID).remove();
    };

    /**
     * Show search Product or Service
     */
    this.showSearchProductService = function () {
        var parentElement = $(this).parent();
        $(parentElement).toggleClass("pressed");
        $(parentElement).find(".wrap-input .typeSearch").focus();

        if (!$(parentElement).hasClass("pressed")) {
            $(parentElement).find(".wrap-input .typeSearch").blur();
            $(parentElement).find(".wrap-input .typeSearch").val("");
            $(document)
                .find(
                    ".wrap-add-product-service .main-content .wrap-search .wrap-result"
                )
                .remove();
        }
    };

    /**
     * Search Product or Service
     */
    this.searchProductService = function () {
        var stringToSearch = $(this).val();

        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            that.processSearchProductService(stringToSearch);
        }, delay);
    };

    /**
     * proccess the info to search
     * @param stringToSearch
     */
    this.processSearchProductService = function (stringToSearch) {
        //ajax settings
        var settings = {
            url: searchProductServiceRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                text: stringToSearch,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                let wrapResult = '<div class="wrap-result">';

                if (response["products"].length > 0) {
                    $.each(response["products"], function (index, value) {
                        let price = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                        }).format(value.price);

                        wrapResult += '<div class="item">';
                        wrapResult +=
                            '<label class="product-service-selected" data-id="' +
                            value.id +
                            '" data-name="' +
                            value.name +
                            '" data-price="' +
                            value.price +
                            '" data-type="product">' +
                            value.name +
                            "<span>" +
                            price +
                            "</span></label>";
                        wrapResult += "</div>";
                    });
                }
                if (response["therapies"].length > 0) {
                    $.each(response["therapies"], function (index, value) {
                        let price = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                        }).format(value.price);

                        wrapResult += '<div class="item">';
                        wrapResult +=
                            '<label class="product-service-selected" data-id="' +
                            value.id +
                            '" data-name="' +
                            value.name +
                            '" data-price="' +
                            value.price +
                            '" data-type="therapy">' +
                            value.name +
                            "<span>" +
                            price +
                            "</span></label>";
                        wrapResult += "</div>";
                    });
                }

                if (
                    response["products"].length == 0 &&
                    response["therapies"].length == 0
                ) {
                    wrapResult += '<div class="item">';
                    wrapResult +=
                        '<label>No se encontraron resultados para <span style="float:none;"> "' +
                        stringToSearch +
                        '"</span></label>';
                    wrapResult += "</div>";
                }

                wrapResult += "</div>";

                $(document)
                    .find(
                        ".wrap-add-product-service .main-content .wrap-search .wrap-result"
                    )
                    .remove();
                $(document)
                    .find(
                        ".wrap-add-product-service .main-content .wrap-search"
                    )
                    .append(wrapResult);
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * product or service has been selected
     */
    this.addSelectedProductService = function () {
        let id = $(this).data("id");
        let name = $(this).data("name");
        let price = $(this).data("price");
        let type = $(this).data("type");

        $(document)
            .find(".wrap-add-product-service .main-content .body #prod-serv-id")
            .val(id);
        $(document)
            .find(
                ".wrap-add-product-service .main-content .body #prod-serv-concept"
            )
            .val(name);
        $(document)
            .find(
                ".wrap-add-product-service .main-content .body #prod-serv-price"
            )
            .val(price);
        $(document)
            .find(
                ".wrap-add-product-service .main-content .body #prod-serv-type"
            )
            .val(type);

        //clean search stuff
        $(document)
            .find(
                ".wrap-add-product-service .main-content .wrap-search .wrap-result"
            )
            .remove();
        $(document)
            .find(".wrap-add-product-service .main-content .search-patient")
            .trigger("click");
    };

    /**
     * Add Product or service
     */
    this.addProductService = function () {
        Swal.fire({
            html: addPSHTML,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });
    };

    /**
     * Save the new product or service
     */
    this.saveAddProductService = function () {
        var prodServIDInput = $(
            ".wrap-add-product-service .main-content .body #prod-serv-id"
        );
        var prodServNameInput = $(
            ".wrap-add-product-service .main-content .body #prod-serv-concept"
        );
        var prodServPriceInput = $(
            ".wrap-add-product-service .main-content .body #prod-serv-price"
        );
        var prodServTypeInput = $(
            ".wrap-add-product-service .main-content .body #prod-serv-type"
        );
        var quantityServTypeInput = $(
            ".wrap-add-product-service .main-content .body #quantity-serv-price"
        );
        var validationPass = true;

        $(prodServNameInput).removeClass("error");
        $(prodServPriceInput).removeClass("error");
        $(".wrap-add-product-service .main-content .wrap-error label").removeClass("show");

        if ($.trim($(prodServNameInput).val()) == "") {
            $(prodServNameInput).addClass("error");
            validationPass = false;
            $(
                ".wrap-add-product-service .main-content .wrap-error label"
            ).addClass("show");
            $(".wrap-add-product-service .main-content .wrap-error label").html(
                "Por favor, verifica ambos campos."
            );
        }
        if (
            $.trim($(prodServPriceInput).val()) == "" ||
            $(prodServPriceInput).val() <= 0
        ) {
            $(prodServPriceInput).addClass("error");
            validationPass = false;
            $(
                ".wrap-add-product-service .main-content .wrap-error label"
            ).addClass("show");
            $(".wrap-add-product-service .main-content .wrap-error label").html(
                "Por favor, verifica ambos campos."
            );
        }
        if (isNaN($(prodServPriceInput).val())) {
            $(prodServPriceInput).addClass("error");
            validationPass = false;
            $(
                ".wrap-add-product-service .main-content .wrap-error label"
            ).addClass("show");
            $(".wrap-add-product-service .main-content .wrap-error label").html(
                "Verifique que el precio tenga un valor numérico."
            );
        }

        if (validationPass) {
            that.processSaveNewProductService(
                $(prodServIDInput).val(),
                $(prodServNameInput).val(),
                $(prodServPriceInput).val(),
                $(prodServTypeInput).val(),
                $(quantityServTypeInput).val()
            );
        }
    };

    /**
     * ajax to save in db service or product
     * @param id
     * @param name
     * @param price
     * @param type
     */
    this.processSaveNewProductService = function (id, name, price, type, quantity) {
        //wait alert
        Swal.fire({
            title: "Guardando...",
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        //ajax settings
        var settings = {
            url: saveNewProductServiceRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                id: id,
                name: name,
                price: price,
                type: type,
                quantity: quantity,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                var iprice = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(price);

                var newHtml = "";
                newHtml += '<div class="history-data_inf-item">';
                newHtml +=
                    '<span class="history-data_name">' + name + "</span>";
                newHtml +=
                    '<span class="history-data_date">' + iprice + "</span>";
                newHtml +=
                    '<span class="history-remove"><a href="javascript:;" class="remove-item" data-remove="' +
                    response.id +
                    '"></a></span>';
                newHtml += "</div>";

                $(".history-data_inf").prepend(newHtml);

                Swal.fire(
                    "Listo!",
                    "Producto o servicio agregado a la cuenta",
                    "success"
                ).then(function () {
                    location.reload();
                });
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Remove product / service
     */
    this.removeProductService = function () {
        var productServiceID = $(this).data("remove");
        var residencePaymentID = $(this).data("respay");
        var productName = $(this).data("product");
        var currentQuantity = $(this).data("quantity");

        Swal.fire({
            title: "¿Estás seguro de que deseas eliminar "+ productName +"?",
            html: '<label>Selecciona la cantidad a eliminar</label><br><input type="number" id="quantity_to_delete" class="swal2-input" placeholder="Cantidad" min="1" max="'+currentQuantity+'" value="'+currentQuantity+'"/>',
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: `Cancelar`,
            preConfirm: () => {
                const cantidad = document.getElementById("quantity_to_delete").value;
                if (!cantidad || cantidad <= 0) {
                    Swal.showValidationMessage("Por favor, ingresa una cantidad válida.");
                }
                return { cantidad: cantidad };
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //wait alert
                Swal.fire({
                    title: "Eliminando...",
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                //ajax settings
                var settings = {
                    url: removeProductServiceRoute,
                    method: "POST",
                    data: {
                        _token: $(document)
                            .find('meta[name="csrf-token"]')
                            .attr("content"),
                        product_service_id: productServiceID,
                        residence_payment_id: residencePaymentID,
                        quantity: result.value.cantidad,
                    },
                };

                //ajax call
                $.ajax(settings)
                    .done(function (response) {
                        $(".product-service-" + productServiceID).remove();

                        Swal.fire(
                            "Listo!",
                            "Producto o servicio eliminado",
                            "success"
                        ).then(function () {
                            location.reload();
                        });
                    })
                    .fail(function (xhr, status, error) {
                        //ajax call fail
                        Swal.fire("Ocurrió un error", error, "error");
                    });
            }
        });
    };

    /**
     * Calculate cost for stay
     */
    this.calculateResidenceCost = function () {
        var checkInDate = new Date($("#patient-form").find("#check-in").val());
        var checkOutDate = new Date(
            $("#patient-form").find("#check-out").val()
        );
        checkInDate.setHours(0, 0, 0, 0);
        checkOutDate.setHours(0, 0, 0, 0);
        let difference = checkOutDate.getTime() - checkInDate.getTime();
        let TotalDays = Math.round(difference / (1000 * 3600 * 24));

        var cost = dailyCostForResidence * TotalDays;

        if (!isNaN(cost)) {
            if (cost < 0) {
                cost = 0;
            }

            var icost = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(cost);

            $("#total_account").val(cost);
            $("#total_account_format").val(icost);
        }
    };

    /**
     * show / hide ID fields from payment window
     */
    this.toggleIDFields = function () {
        let currentValue = $(this).val();

        if (currentValue != "efectivo") {
            $(".wrap-payment .main-content .body .wrap-id").removeClass(
                "d-none"
            );
        } else {
            $(".wrap-payment .main-content .body .wrap-id").addClass("d-none");
        }
    };

    /**
     * show / hide Partial payment fields from payment window
     */
    this.togglePartialPaymentFields = function () {
        if ($(this).is(":checked")) {
            $(".wrap-payment .main-content .body .wrap-partial, .wrap-payment .main-content .body .wrap-products").removeClass(
                "d-none"
            );
        } else {
            $(".wrap-payment .main-content .body .wrap-partial, .wrap-payment .main-content .body .wrap-products").addClass(
                "d-none"
            );
        }
    };

    /**
     * add / remove product price to pay
     */
    this.togglePriceProductToPay = function () {
        let productPrice = $(this).data('price');
        let currentTotal = $('#partial_amount_payment').val();

        currentTotal = parseFloat(currentTotal);
        productPrice = parseFloat(productPrice);

        if ($(this).is(":checked")) {
            $('#partial_amount_payment').val(currentTotal + productPrice);
        } else {
            $('#partial_amount_payment').val(currentTotal - productPrice);
        }
    };

    /**
     * add / remove therapy price to pay
     */
    this.togglePriceTherapyToPay = function () {
        let therapyPrice = $(this).data('price');
        let currentTotal = $('#partial_amount_payment').val();

        currentTotal = parseFloat(currentTotal);
        therapyPrice = parseFloat(therapyPrice);

        if ($(this).is(":checked")) {
            $('#partial_amount_payment').val(currentTotal + therapyPrice);
        } else {
            $('#partial_amount_payment').val(currentTotal - therapyPrice);
        }
    };

    /**
     * Show payment window
     */
    this.showPaymentWindow = function () {
        let paymentType = $(this).data('type');
        let HTMLtoShow = (paymentType == 'checkout') ? paymentHTML : partialPaymentHTML;

        Swal.fire({
            html: HTMLtoShow,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });
    };

    /**
     * Show discount window
     */
    this.showAddDiscount = function () {
        Swal.fire({
            html: discountHTML,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });
    };

    /**
     * Show product discount window
     */
    this.showAddProductDiscount = function () {
        Swal.fire({
            html: productDiscountHTML,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });

        let productCost = $(this).data("currentcost");
        $(document)
            .find(".wrap-payment .main-content .body .current-product-cost")
            .html("$" + productCost);
        $(document)
            .find(".wrap-payment .main-content .body .after-discount")
            .html("$" + productCost);
        $(document)
            .find(".wrap-payment .main-content .body #product_cost")
            .val(productCost);
    };

    /**
     * Save discount
     */
    this.doTheDiscount = function () {
        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_discount"
        ).val();
        let roomCost = $(".wrap-payment .main-content .body #room_cost").val();
        let couponCode = $(
            ".wrap-payment .main-content .body #coupon_code"
        ).val();
        let couponUsed = $(
            ".wrap-payment .main-content .body #coupon_used"
        ).val();

        $(".wrap-payment .main-content .wrap-error label").removeClass("show");

        if ($(discountType).val() == "") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, seleccione el tipo de descuento"
            );
            return;
        }
        if (amountDiscount == "" || amountDiscount == "0") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, ingrese el monto a descontar"
            );
            return;
        }

        //wait alert
        Swal.fire({
            title: "Por favor, espere...",
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        //ajax settings
        var settings = {
            url: doDiscountRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                method_discount: $(discountType).val(),
                total_to_discount: amountDiscount,
                room_cost: roomCost,
                coupon_code: couponCode,
                coupon_used: couponUsed,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                Swal.fire(
                    "Listo!",
                    "El descuento ha sido aplicado",
                    "success"
                ).then(function () {
                    //window.location.href = dashboardRoute;
                    location.reload();
                });
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Save product discount
     */
    this.doTheProductDiscount = function () {
        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_product_discount"
        ).val();
        let productCost = $(
            ".wrap-payment .main-content .body #product_cost"
        ).val();
        let couponCode = $(
            ".wrap-payment .main-content .body #coupon_code"
        ).val();
        let couponUsed = $(
            ".wrap-payment .main-content .body #coupon_used"
        ).val();

        $(".wrap-payment .main-content .wrap-error label").removeClass("show");

        if ($(discountType).val() == "") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, seleccione el tipo de descuento"
            );
            return;
        }
        if (amountDiscount == "" || amountDiscount == "0") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, ingrese el monto a descontar"
            );
            return;
        }

        //wait alert
        Swal.fire({
            title: "Por favor, espere...",
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        //ajax settings
        var settings = {
            url: doProductDiscountRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                method_discount: $(discountType).val(),
                total_to_discount: amountDiscount,
                product_cost: productCost,
                coupon_code: couponCode,
                coupon_used: couponUsed,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                Swal.fire(
                    "Listo!",
                    "El descuento ha sido aplicado",
                    "success"
                ).then(function () {
                    location.reload();
                });
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Calculate discount
     */
    this.calculateTheDiscount = function () {
        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_discount"
        ).val();

        if ($(discountType).val() == "porcentaje") {
            let discount = Math.floor(roomCost * amountDiscount) / 100;
            let afterDiscount = $.number(roomCost - discount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
            $(".wrap-payment .main-content .body #room_cost").val(roomCost);
        } else {
            let afterDiscount = $.number(roomCost - amountDiscount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
        }

        $(".wrap-payment .main-content .body .after-discount").addClass(
            "latidos"
        );

        setTimeout(function () {
            $(".wrap-payment .main-content .body .after-discount").removeClass(
                "latidos"
            );
        }, 2000);
    };

    /**
     * Calculate product discount
     */
    this.calculateTheProductDiscount = function () {
        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_product_discount"
        ).val();
        let productCost = $(
            ".wrap-payment .main-content .body #product_cost"
        ).val();

        if ($(discountType).val() == "porcentaje") {
            let discount = Math.floor(productCost * amountDiscount) / 100;
            let afterDiscount = $.number(productCost - discount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
            $(".wrap-payment .main-content .body #product_cost").val(
                productCost
            );
        } else {
            let afterDiscount = $.number(productCost - amountDiscount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
        }

        $(".wrap-payment .main-content .body .after-discount").addClass(
            "latidos"
        );

        setTimeout(function () {
            $(".wrap-payment .main-content .body .after-discount").removeClass(
                "latidos"
            );
        }, 2000);
    };

    /**
     * Calculate total account discount
     */
    this.showAddTotalAccountDiscount = function () {

        Swal.fire({
            html: totalAccountDiscountHTML,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });

        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_discount"
        ).val();
        let totalAccountCost = $(
            ".wrap-payment .main-content .body #total_account_cost"
        ).val();

        if ($(discountType).val() == "porcentaje") {
            let discount = Math.floor(totalAccountCost * amountDiscount) / 100;
            let afterDiscount = $.number(totalAccountCost - discount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
            $(".wrap-payment .main-content .body #total_account_cost").val(
                totalAccountCost
            );
        } else {
            let afterDiscount = $.number(totalAccountCost - amountDiscount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
        }

        $(".wrap-payment .main-content .body .after-discount").addClass(
            "latidos"
        );

        setTimeout(function () {
            $(".wrap-payment .main-content .body .after-discount").removeClass(
                "latidos"
            );
        }, 2000);
    };

    /**
     * Calculate total account discount
     */
    this.calculateTheAccountDiscount = function () {
        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_discount"
        ).val();
        let totalAccountCost = $(
            ".wrap-payment .main-content .body #total_account_cost"
        ).val();


        if ($(discountType).val() == "porcentaje") {
            let discount = Math.floor(totalAccountCost * amountDiscount) / 100;
            let afterDiscount = $.number(totalAccountCost - discount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
            $(".wrap-payment .main-content .body #total_account_cost").val(
                totalAccountCost
            );
        } else {
            let afterDiscount = $.number(totalAccountCost - amountDiscount);
            $(".wrap-payment .main-content .body .after-discount").html(
                "$" + afterDiscount
            );
        }

        $(".wrap-payment .main-content .body .after-discount").addClass(
            "latidos"
        );

        setTimeout(function () {
            $(".wrap-payment .main-content .body .after-discount").removeClass(
                "latidos"
            );
        }, 2000);
    };

    /**
     * Save total account discount
     */
    this.doTheAccountDiscount = function () {
        let discountType = $(".wrap-payment .main-content .body select");
        let amountDiscount = $(
            ".wrap-payment .main-content .body #amount_discount"
        ).val();
        let totalAccountCost = $(
            ".wrap-payment .main-content .body #total_account_cost"
        ).val();
        let couponCode = $(
            ".wrap-payment .main-content .body #coupon_code"
        ).val();
        let couponUsed = $(
            ".wrap-payment .main-content .body #coupon_used"
        ).val();

        $(".wrap-payment .main-content .wrap-error label").removeClass("show");

        if ($(discountType).val() == "") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, seleccione el tipo de descuento"
            );
            return;
        }
        if (amountDiscount == "" || amountDiscount == "0") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, ingrese el monto a descontar"
            );
            return;
        }

        //wait alert
        Swal.fire({
            title: "Por favor, espere...",
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        //ajax settings
        var settings = {
            url: doTotalAccountDiscountRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                method_discount: $(discountType).val(),
                total_to_discount: amountDiscount,
                total_account_cost: totalAccountCost,
                coupon_code: couponCode,
                coupon_used: couponUsed,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                Swal.fire(
                    "Listo!",
                    "El descuento ha sido aplicado",
                    "success"
                ).then(function () {
                    location.reload();
                });
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Do the payment and validation
     */
    this.doThePayment = function () {
        let paymentMethod = $(".wrap-payment .main-content .body select");
        let authNumber = $(
            ".wrap-payment .main-content .body #authorization_number"
        ).val();
        let idNumber = $(
            ".wrap-payment .main-content .body #identification_number"
        ).val();
        let partialPayment = $(
            ".wrap-payment .main-content .body #partial_payment"
        );
        let partialAmountPayment = $(
            ".wrap-payment .main-content .body #partial_amount_payment"
        ).val();
        let checkoutWithFood = $(
            ".wrap-payment .main-content .body #checkout_with_food"
        );
        var productSelectedForPay = [];
        var therapySelectedForPay = [];

        $('.product-to-pay:checked').each(function() {
            productSelectedForPay.push($(this).val());
        });
        $('.therapy-to-pay:checked').each(function() {
            therapySelectedForPay.push($(this).val());
        });
        //upload voucher if exist
        that.uploadVoucher();

        $(".wrap-payment .main-content .wrap-error label").removeClass("show");

        if ($(paymentMethod).val() == "") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, seleccione el método de pago"
            );
            return;
        }
        if (
            $(paymentMethod).val() != "efectivo" &&
            (authNumber == "" || idNumber == "")
        ) {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, llene los campos para la idetificación de transacción bancaria"
            );
            return;
        }

        if ($(partialPayment).is(":checked") && partialAmountPayment == "") {
            $(".wrap-payment .main-content .wrap-error label").addClass("show");
            $(".wrap-payment .main-content .wrap-error label").html(
                "Por favor, ingrese la cantidad a abonar"
            );
            return;
        }

        //wait alert
        Swal.fire({
            title: "Por favor, espere...",
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        //ajax settings
        var settings = {
            url: doPaymentRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                method_payment: $(paymentMethod).val(),
                authorization_number: authNumber,
                identification_number: idNumber,
                partial_payment: $(partialPayment).is(":checked"),
                partial_amount_payment: partialAmountPayment,
                checkout_with_food: $(checkoutWithFood).is(":checked"),
                total_to_pay: totalToPay,
                product_for_payment: productSelectedForPay,
                therapy_for_payment: therapySelectedForPay
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {

                if(response == 'partial-payment') {
                    Swal.fire(
                        "Listo!",
                        "Los cambios han quedado aplicados a la cuenta",
                        "success"
                    ).then(function () {
                        window.open(printTicketRoute, "_blank");
                        location.reload();
                        //window.location.href = dashboardRoute;
                        //open ticket window
                    });
                    return;
                }

                Swal.fire(
                    "Listo!",
                    "La cuenta ha quedado pagada en su totalidad",
                    "success"
                ).then(function () {
                    window.open(printTicketRoute, "_blank");
                    //location.reload();
                    window.location.href = dashboardRoute;
                    //open ticket window
                });
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Upload voucher
     */
    this.uploadVoucher = function () {
        // Get the selected file
        var files = $("#voucher_file")[0].files;

        if (files.length > 0) {
            var fd = new FormData();

            // Append data
            fd.append("file", files[0]);
            fd.append(
                "_token",
                $(document).find('meta[name="csrf-token"]').attr("content")
            );

            // AJAX request
            $.ajax({
                url: uploadVoucherRoute,
                method: "post",
                data: fd,
                contentType: false,
                processData: false,
                dataType: "json",
                success: function (response) {},
                error: function (response) {
                    console.log("error : " + JSON.stringify(response));
                },
            });
        }
    };

    /**
     * show futures reservation for room selected
     */
    this.showFuturesReservations = function () {
        var roomID = $(this).data("roomselected");

        //ajax settings
        let settings = {
            url: getFuturesReservationsRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                room_id: roomID,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                that.futuresVisits(response.dates, response.room_name);
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Show alert with futures visit dates
     * @param dates
     * @param room
     */
    this.futuresVisits = function (dates, room) {
        var html = "";
        html +=
            '<div class="wrap-change-stay-date">\n' +
            '    <div class="main-content">\n' +
            '        <div class="head">\n' +
            "            <label>Próximas visitas para habitación " +
            room +
            "</label>\n" +
            '            <button type="button" class="close"></button>\n' +
            "        </div>";

        $.each(dates, function (index, val) {
            html += '<div class="body">';
            html += '<div class="wrap-item justify-space-around dFlex">';
            html += "<label><strong>" + val.patient_name + "</strong></label>";
            html += "<label>" + val.future_date + "</label>";
            html += '<div class="wrap-item justify-center gap10 mt-2 dFlex">';
            html +=
                '<button class="btnInSwal btnSmall change-room" data-patient="' +
                val.nickname +
                '" data-residenceid="' +
                val.residence_id +
                '" data-currentroom="' +
                room +
                '">Cambiar habitación</button>';
            html +=
                '<button class="btnInSwal btnSmall change-reservation" data-checkin="' +
                val.check_in +
                '" data-checkout="' +
                val.check_out +
                '" data-patient="' +
                val.nickname +
                '" data-residenceid="' +
                val.residence_id +
                '" data-currentroom="' +
                room +
                '">Cambiar reservación</button>';
            html += "</div>";
            html += "</div>";

        });

        if (dates.length == 0) {
            html += '<div class="body">';
            html +=
                '<label class="text-center"> No existen reservaciones para esta habitación</label>';
            html += "</div>";
        }

        html += "</div>\n" + "  </div>";

        Swal.fire({
            html: html,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });
    };

    /**
     * show available rooms
     */
    this.showChangeRoomForm = function () {
        let patientName = $(this).data("patient");
        let residenceID = $(this).data("residenceid");
        var html = "";
        //ajax settings
        var settings = {
            url: getAvailableRoomsRoute,
            method: "GET",
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                html +=
                    '<div class="wrap-change-stay-date">' +
                    '    <div class="main-content">' +
                    '        <div class="head">' +
                    "            <label>Seleccione nueva habitación para " +
                    patientName +
                    "</label>" +
                    '            <button type="button" class="close"></button>' +
                    "        </div>" +
                    '        <div class="body-row">' +
                    '        <input type="hidden" id="change-room-residence" value="' +
                    residenceID +
                    '">' +
                    '           <select id="new-room-selected" class="custom-select">';

                $.each(response, function (index, val) {
                    html += '<option value="' + index + '">';
                    html += "Habitación " + val;
                    html += "</option>";
                });

                html +=
                    "</select>" +
                    ' <div class="wrap-buttons d-flex justify-content-centerc align-items-center">' +
                    '    <button type="button" class="cancel">Cancelar</button>' +
                    '    <button type="button" class="btnInSwal save-change-room">Cambiar</button>' +
                    " </div>" +
                    " </div>";

                Swal.fire({
                    html: html,
                    showCloseButton: false,
                    showCancelButton: false,
                    showConfirmButton: false,
                    customClass: "wrap-add-product-service new-room",
                });
            })
            .fail(function (xhr, status, error) {
                return "error";
            });
    };

    /**
     * Save room change
     */
    this.saveChangeRoom = function () {
        let newRoom = $("#new-room-selected").val();
        let residenceID = $("#change-room-residence").val();

        //ajax settings
        var settings = {
            url: updateReservationRoomRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                residence_id: residenceID,
                new_room_id: newRoom,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                Swal.fire(
                    "Listo!",
                    "Nueva habitación asignada",
                    "success"
                ).then(function () {
                    location.reload();
                });
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * show change reservation
     */
    this.showChangeReservation = function () {
        let patientName = $(this).data("patient");
        let residenceID = $(this).data("residenceid");
        let checkInDate = $(this).data("checkin");
        let checkOutDate = $(this).data("checkout");
        var html = "";

        html +=
            '<div class="wrap-change-stay-date">' +
            '    <div class="main-content">' +
            '        <div class="head">' +
            "            <label>Seleccione nueva fecha de reserva para " +
            patientName +
            "</label>" +
            '            <button type="button" class="close"></button>' +
            "        </div>" +
            '        <div class="body-row">' +
            '        <label for="new-check-in-date">Entrada</label>' +
            '        <label for="new-check-in-date" class="title">' +
            checkInDate +
            "</label>" +
            '        <input id="new-check-in-date" value="' +
            checkInDate +
            '" class="form-item date-type" required="required" name="check_in" type="date">' +
            '        <label for="new-check-out-date">Salida</label>' +
            '        <label for="new-check-out-date" class="title">' +
            checkOutDate +
            "</label>" +
            '        <input id="new-check-out-date" value="' +
            checkOutDate +
            '" class="form-item date-type" required="required" name="check_out" type="date">' +
            '        <input type="hidden" id="residence-to-change-id" value="' +
            residenceID +
            '">' +
            ' <div class="wrap-buttons d-flex justify-content-centerc align-items-center">' +
            '    <button type="button" class="cancel">Cancelar</button>' +
            '    <button type="button" class="btnStrd no-margin save-change-reservation">Cambiar</button>' +
            " </div>" +
            " </div>";

        Swal.fire({
            html: html,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "wrap-add-product-service new-room",
        });
    };

    /**
     * Change new reservation date
     */
    this.saveChangeReservation = function () {
        let residenceID = $("#residence-to-change-id").val();
        let newCheckInDate = $("#new-check-in-date").val();
        let newCheckOutDate = $("#new-check-out-date").val();

        //ajax settings
        var settings = {
            url: updateReservationDateRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                residence_id: residenceID,
                check_in: newCheckInDate,
                check_out: newCheckOutDate,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                Swal.fire("Listo!", "Reservación actualizada", "success").then(
                    function () {
                        location.reload();
                    }
                );
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Date crossing
     */
    this.checkForDateCrossing = function () {
        let checkInSelected = $(this).val();
        let roomID = $("#room_id").val();
        let typeOfDate = $(this).attr("id");

        //get the futures dates for the room selected
        //ajax settings
        var settings = {
            url: checkForDateCrossingRoute,
            method: "POST",
            data: {
                _token: $(document)
                    .find('meta[name="csrf-token"]')
                    .attr("content"),
                room_id: roomID,
                date_selected: checkInSelected,
                type_of_date: typeOfDate,
            },
        };

        //ajax call
        $.ajax(settings)
            .done(function (response) {
                if (response.cross_date) {
                    frontDesk.showAlert(
                        "Fecha en conflicto",
                        "Hay un cruce de fechas en la reservación. Por favor, verifique las fechas futuras en la habitación seleccionada",
                        false,
                        "warning"
                    );
                }
            })
            .fail(function (xhr, status, error) {
                //ajax call fail
                Swal.fire("Ocurrió un error", error, "error");
            });
    };

    /**
     * Play welcome video
     */
    this.playVideo = function () {
        $(".playVideo").on("click", function () {
            $(this).addClass("d-none");
            $("#patientVideo").get(0).play();
        });
    };

    /**
     * Upload eyes images
     */
    this.uploadEyesImages = function () {
        let parentContainer = $(this).data('parent');
        let eyeSide = $(this).data('side');
        // Get the selected file
        var files = $("#"+ parentContainer +" .image-container")[0].files;

        if (files.length > 0) {
            var file = files[0];

            // Check file type
            if (!file.type.startsWith('image/')) {
                Swal.fire("Imagen no válida", "Por favor selecciona una imagen válida.", 'warning');
                return;
            }

            var fd = new FormData();

            // Append data
            fd.append("file", file);
            fd.append(
                "_token",
                $(document).find('meta[name="csrf-token"]').attr("content"),
            );
            fd.append(
                "eye_side",
                eyeSide,
            );

            // AJAX request
            $.ajax({
                url: uploadEyesImagesRoute,
                method: "post",
                data: fd,
                contentType: false,
                processData: false,
                dataType: "json",
                success: function (response) {
                    Swal.fire("", "Imagen cargada correctamente", "success");
                    $("#"+ parentContainer +" .image-container").val('');
                    var randomNumber = Math.floor(Math.random() * 101);

                    //create thumbnail
                    let thumbnailElement = $('<div class="image-thumbnail" data-image="'+response.url + '?v='+randomNumber+'"><img src="'+response.url + '?v='+randomNumber+'" alt="Imagen" style="width: 100px; height: auto; cursor: pointer;">');

                    $('.wrap-thumbnail').append(thumbnailElement);

                },
                error: function (response) {
                    Swal.fire("", "Ocurrió un error al intentar cargar la imagen. Por favor verica que el formato sea correcto y vuelve a intentarlo", "error");
                    console.log("error : " + JSON.stringify(response));
                    $("#"+ parentContainer +" .image-container").val('');
                },
            });
        }

        //check for the webcam image
        if(files.length == 0) {
            let webcamImage = $('#'+ parentContainer).find('.webcam-patient-img').val();
            let file = that.base64ToFile(webcamImage, 'image.jpg');
            const formData = new FormData();
            formData.append('file', file);
            formData.append(
                "_token",
                $(document).find('meta[name="csrf-token"]').attr("content"),
            );
            formData.append(
                "eye_side",
                eyeSide,
            );

            // AJAX request
            $.ajax({
                url: uploadEyesImagesRoute,
                method: "post",
                data: formData,
                contentType: false,
                processData: false,
                dataType: "json",
                success: function (response) {
                    Swal.fire("", "Imagen cargada correctamente", "success");
                    $("#"+ parentContainer +" .image-container").val('');
                    var randomNumber = Math.floor(Math.random() * 101);

                    //create thumbnail
                    let thumbnailElement = $('<div class="image-thumbnail" data-image="'+response.url + '?v='+randomNumber+'"><img src="'+response.url + '?v='+randomNumber+'" alt="Imagen" style="width: 100px; height: auto; cursor: pointer;">');

                    $('.wrap-thumbnail').append(thumbnailElement);

                },
                error: function (response) {
                    Swal.fire("", "Ocurrió un error al intentar cargar la imagen. Por favor verica que el formato sea correcto y vuelve a intentarlo", "error");
                    console.log("error : " + JSON.stringify(response));
                    $("#"+ parentContainer +" .image-container").val('');
                },
            });
        }
    };

    /**
     * base64 image convert
     */
    this.base64ToFile = function(base64String, filename) {
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    /**
     * Search coupons
     */
    this.searchCoupon = function () {
        let theInput = $(this);
        let couponCode = $(this).val();
        let applyCouponTo = $(this).next().val();
        let partentContentCouponTo = $(this).next().next().next().val();
        let messageElement = $(this).next().next().next().next();
        let numOfCharacters = $(this).val().length;

        //console.log(theInput);
        //console.log(couponCode);
        //console.log(applyCouponTo);
        //console.log(partentContentCouponTo);
        //console.log(messageElement);

        $(messageElement).html("").removeClass("error").removeClass("success");

        if (numOfCharacters == 10) {
            $(theInput).prop("disabled", true);

            //ajax settings
            var settings = {
                url: validateCouponRoute,
                method: "POST",
                data: {
                    _token: $(document)
                        .find('meta[name="csrf-token"]')
                        .attr("content"),
                    coupon_code: couponCode,
                    apply_to: applyCouponTo,
                },
            };

            //ajax call
            $.ajax(settings)
                .done(function (response) {
                    $(theInput).prop("disabled", false);

                    if (typeof response.error !== "undefined") {
                        $(messageElement)
                            .html(response.error)
                            .addClass("error");
                    } else {
                        $(messageElement)
                            .html(response.message)
                            .addClass("success");

                        let discountType =
                            response.data.discount_type == "direct"
                                ? "directo"
                                : "porcentaje";
                        $(document)
                            .find("#"+partentContentCouponTo+".wrap-payment .main-content .body select")
                            .val(discountType)
                            .change();
                        $(document)
                            .find(
                                "#"+partentContentCouponTo+".wrap-payment .main-content .body #amount_discount"
                            )
                            .val(response.data.quantity);
                        $(document)
                            .find(
                                "#"+partentContentCouponTo+".wrap-payment .main-content .body #amount_discount"
                            )
                            .keyup();
                        $(document)
                            .find(
                                "#"+partentContentCouponTo+".wrap-payment .main-content .body #coupon_used"
                            )
                            .val("1");
                    }
                })
                .fail(function (xhr, status, error) {
                    //ajax call fail
                    console.log("falló cupón", error);
                });
        }
    };

    /**
     * Take photo from webcam
     */
    this.takeWebCampPhoto = function () {
        let parentContainer = $(this).data('parent');
        let filedWhitData = "<input type='hidden' value='" + parentContainer + "' id='dataParent'/>";
        cameraHTML += filedWhitData;

        Swal.fire({
            html: cameraHTML,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });
    };

    /**
     * Take the photo
     */
    this.takeThePhoto = function () {
        let webcamImg = $("#results #webcamImg").attr("src");
        let dataParent = $('#dataParent').val();
        $("#" + dataParent).find(".img-thumbnail").attr("src", webcamImg);
        $("#" + dataParent).find(".webcam-patient-img").val(webcamImg);
        Swal.close();
    };

    /**
     * No hosting need it
     */
    this.noHostingNeedIt = function () {
        let noHosting = $(this).is(":checked");

        if (noHosting) {
            $(".wrap-residences").addClass("disabled");
            $(".wrap-residences input").prop("disabled", true);
        } else {
            $(".wrap-residences").removeClass("disabled");
            $(".wrap-residences input").prop("disabled", false);
        }
    };

    /**
     * listen future reservations
     */
    this.listenFutureReservations = function () {
        $(".show-future-reservation").on("click", that.showFuturesReservations);
        $(document).on(
            "click",
            ".wrap-change-stay-date .change-room",
            that.showChangeRoomForm
        );
        $(document).on(
            "click",
            ".wrap-change-stay-date .save-change-room",
            that.saveChangeRoom
        );

        $(document).on(
            "click",
            ".wrap-change-stay-date .change-reservation",
            that.showChangeReservation
        );
        $(document).on(
            "click",
            ".wrap-change-stay-date .save-change-reservation",
            that.saveChangeReservation
        );
    };

    /**
     * WebCam actions
     */
    this.webcamListener = function() {
        //change patient img
        //$("#patient-img").on("change", that.showImgSelected);
        let parentContainer = $(this).data('parent');

        $(".image-container").on("change", that.showImgSelected);
        $(".webcam-img").on("click", that.takeWebCampPhoto);
        $(document).on("click", ".take-it", that.takeThePhoto);
        $(document).on("click", ".close-camera", function() {
            Swal.close();
        });
        $(".change-img").on("click", function () {
            //$("#patient-img").trigger("click");
            const parentContent = $(this).data('parent');
            $("#"+ parentContent).find(".image-container").trigger("click");
        });
    }

})();
