import React, { useMemo, useState } from 'react'
import Modal from 'react-modal';

const ModalYoutube = ({modalIsOpen, setModalIsOpen, el, currentVideo}) => {
    
    Modal.setAppElement(`#${el}`);
    const customStyles = {
        content: {
          width: '60%',
          // height: '95%',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          zIndex: 99,
        },
      };

      const closeModal = (e) => {
        e.preventDefault();
        setModalIsOpen(false);
      }

  return (
        <Modal
            style={customStyles}
            ariaHideApp={false}
            isOpen={modalIsOpen}>
                <div className='youtube-modal'>
                    <a href='#' className='close-modal swal2-close' onClick={closeModal}>x</a>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className='embed-responseive-item' src={`https://www.youtube.com/embed/${currentVideo.youtube_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                
                    </div>
                </div>
        </Modal>
  )
}

export default ModalYoutube;