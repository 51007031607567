import Swal from "sweetalert2";

window.DeleteItem = () => {
    $(".delete-item").on("click", function (e) {
        Swal.fire({
            title: "Estas seguro de eliminar este elemento?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
        }).then((result) => {
            if (result.isConfirmed) {
                let id = $(e.currentTarget).data("id");
                $("#form-delete-" + id).submit();
            }
        });
    });
};
