import React from "react";
import { useMemo } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getFutureReservations } from "../../API/CalendarRooms";
import ModalChangeReservation from "./ModalChangeReservation";
const MySwal = withReactContent(Swal);

const DayStructure = (props) => {
    const { room, day, getData, date } = props;

    const hasResidence = useMemo(() => {
        if (room.residence) {
            const residence = room.residence;
            const dateCheckIn = moment(residence.check_in);
            const dateCheckOut = moment(residence.check_out);
            if (
                dateCheckIn.format("MM") == date.month &&
                dateCheckOut.format("MM") == date.month
            ) {
                if (
                    day >= dateCheckIn.format("DD") &&
                    day <= dateCheckOut.format("DD")
                ) {
                    return residence;
                }
            } else {
                if (dateCheckIn.format("MM") == date.month) {
                    if (day >= dateCheckIn.format("DD")) {
                        return residence;
                    }
                }
                if (dateCheckOut.format("MM") == date.month) {
                    if (day <= dateCheckOut.format("DD")) {
                        return residence;
                    }
                }
            }

            // const dateCheckIn = moment(residence.check_in).format("DD");
            // const dateCheckOut = moment(residence.check_out).format("DD");
            return false;
        }
    }, [room, day]);

    const handleChangeRoom = async () => {
        console.log("enter");
    };

    const handleRoom = async () => {
        if (room.residence.is_reservation == 0) {
            return;
        }
        const data = await getFutureReservations(room.id);
        MySwal.fire({
            html: (
                <ModalChangeReservation
                    data={data}
                    room={room}
                    getData={getData}
                />
            ),
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "swal-add-product-service",
        });
    };

    return (
        <>
            {hasResidence ? (
                <td
                    style={{
                        backgroundColor:
                            hasResidence.is_reservation == 1
                                ? "#447167"
                                : "#ffeb3b",
                    }}
                >
                    <div
                        onClick={handleRoom}
                        style={{
                            fontSize: 12,
                            lineHeight: "14px",
                            cursor:
                                hasResidence.is_reservation == 1
                                    ? "pointer"
                                    : "default",
                            color:
                                hasResidence.is_reservation == 1
                                    ? "#fff"
                                    : "#000",
                        }}
                    >
                        <div
                            style={{
                                color: "#c8c8c8",
                                fontSize: 10,
                                marginBottom: "4px",
                            }}
                        >
                            {hasResidence.is_reservation == 1 ? "Reserva" : ""}
                        </div>
                        {hasResidence.patient.user.name +
                            " " +
                            hasResidence.patient.user.last_name}
                    </div>
                </td>
            ) : (
                <td></td>
            )}
        </>
    );
};

export default DayStructure;
