(function(){

    let showHideItemSubmenu = function(){
        $('.mntl-dash_item').each((index, item) => {
            let handlerShow = $(item).find('.dash-options'),
                handlerHide = $(item).find('.dash-options_menu span'),
                menu = $(item).find('.dash-options_menu');

            handlerShow.on('click', function(){
                $(item).find('.dash-options_menu').addClass('show');
            })
            handlerHide.on('click', function(){
                $(item).find('.dash-options_menu').removeClass('show');
            })
            $(document).on('click', function(event) {
                if (!$(event.target).closest(menu).length && !$(event.target).closest(handlerShow).length) {
                    menu.removeClass('show');
                }
            });
        });
    }

    let daysTab = function(){
        $('.mntl-history_daysFood').hide();
        $('.mntl-history_daysFood').first().show();
        $('.mmtl-history_daysSmall a').first().addClass("current");
        $('.mmtl-history_daysSmall a').on('click', function(){
            let activeTab = $(this).attr('href');
            $('.mmtl-history_daysSmall a').removeClass("current");
            $(this).addClass("current");
            $('.mntl-history_daysFood').hide();
            $(activeTab).show();
            return false;
        });
    }


    //actions

    showHideItemSubmenu();
    daysTab();

})();
