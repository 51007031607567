import Swal from 'sweetalert2';

window.DeleteImageTherapies = () => {
    $('form.form-image').on('submit', function(e) {
        e.preventDefault();
        Swal.fire({
            title: 'Estas seguro de eliminar esta imagen?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if ( result.isConfirmed) {
                e.target.submit();
                return true;
            }
        });
    })
};