/**
 * Patient JS
 *
 */

import Swal from "sweetalert2";
import 'slick-carousel';

window.Coupons = new (function () {

    const that = this;

    /**
     * Initialize stuff
     */
    this.init = function () {

        //tooltips
        $('.tooltip').tooltip();

        $('.autoGenerateCouponCode').on('click', that.generateCouponCode);

    };


    /**
     * ajax to save in db service or product
     * @param id
     * @param name
     * @param price
     * @param type
     */
    this.generateCouponCode = function () {

        //wait alert
        Swal.fire({
            title: 'Generando código...',
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //ajax settings
        var settings = {
            url: 'generate-code',
            method: "GET",
        }

        //ajax call
        $.ajax(settings).done(function (response) {

            $('#code').val(response);
            Swal.close();

        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrió un error',
                error,
                'error'
            );
        });

    };



})();
