import React, { useMemo, useState } from 'react'
import Select from 'react-select';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import { addTherapy } from '../resources';

const ModalTherapy = ({currentTherapy, arrayDays, modalIsOpen, setModalIsOpen, room, el}) => {

    Modal.setAppElement(`#${el}`);
    const[daySelected, setDaySelected] = useState('');
    const[hourSelected, setHourSelected] = useState('');

    const customStyles = {
        content: {
          width: '500px',
          height: 'auto',
          minHeight: '440px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };

    const daysSelect = useMemo(() => {
        if(arrayDays) {
            return arrayDays.map((d) => {
                return {
                    label: d.date,
                    value: d.raw_date
                }
            })
        }
    }, [arrayDays]);

    const hoursOptions = useMemo(() => {
        if(daySelected) {
            let day = arrayDays.find(d => {
                if (d.raw_date == daySelected) {
                    return d;
                }
            })
            if(day) {
                let options = [{
                    label: 'Selecciona una hora',
                    value: '',
                }];
                day.appointments.map(a => {
                    if(!a.food && a.appointment == null) {
                        options.push({
                            label: a.hour,
                            value: a.hour,
                        })
                    }
                })
                return options;
            } else {
                return [];
            }
        }
    },[daySelected]);

    const handleDay = (opt) => {
        const value = opt.value;
        setDaySelected(value);
        console.log(value);
    }

    const handleSolicitar = async() => {
        console.log(daySelected, hourSelected);
        if(!daySelected || !hourSelected) {
            alert('Por favor seleciona el dia y la hora');
        }
        const response = await addTherapy(room.residence.id, currentTherapy.id, daySelected, hourSelected, 1);
        if ( response.save ) {
            Swal.fire('Solicitado',' Se ha solicitado correctamente', 'success');
            setModalIsOpen(false);
            // alert('Se ha guardado Correctamente');
        }
    }
  return (
        <Modal
            style={customStyles}
            ariaHideApp={false}
            isOpen={modalIsOpen}>
                <>
                <div className="sweetalert-header">
                    <div className="hours">Terapia</div>
                    <div className="terapia">{currentTherapy.name}</div>
                </div>
                <div style={{margin: 15}}>
                    <div className='basic-data'>
                        <div>
                            <strong>Dia</strong>
                            <Select onChange={handleDay} options={daysSelect} placeholder="Selecciona un dia"  />
                        </div>
                        <div style={{marginTop: 10}}>
                            {hoursOptions &&
                            <>
                                <strong>Hora</strong>
                                <Select onChange={(opt) => setHourSelected(opt.value)} options={hoursOptions} placeholder="Selecciona una hora"  />
                            </>
                            }
                        </div>
                        <div className='options-modal buttons'>
                            <button className='btn1' onClick={(e) => setModalIsOpen(false)}>Cancelar</button>
                            <button className='btn2' onClick={(e) => handleSolicitar()}>Solicitar</button>
                        </div>
                    </div>
                </div>
                </>
        </Modal>
  )
}

export default ModalTherapy
