import 'slick-carousel';
import Swal from 'sweetalert2';

window.GalleryTherapy = () => {

    $('.carousel').slick({
        autoplay: true,
        dots: true,
    });

};
