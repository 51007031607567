import 'slick-carousel';
import Swal from 'sweetalert2';

window.CatalogTherapies = () => {

    $('.carousel').slick({
        slidesToShow: 3,
        // arrows: false,
        infinite: false,
        centerMode: true,
        initialSlide: 1,
    });

};
