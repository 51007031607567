import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import Slider from 'react-slick';
import { getTherapy } from '../resources';
import ModalTherapy from './ModalTherapy';

const TherapyDetails = () => {
    const pathArray = window.location.pathname.split('terapias/');
    const therapy_id = pathArray[1] ?? false;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [data, setData] = useState({
        therapy: {},
    });
    const {therapy} = data;

    useEffect(() => {
        const getTherapyWS = async() => {
            const response = await getTherapy(therapy_id);
            setData(response);
        }
        getTherapyWS();
    },[]);

    const settings = {
        slidesToShow: 1,
    }

    return (
        <div id="catalog-therapies-detail" className="dash-home" style={{width: '100%'}}>
            <div className="row">
                <div className="content-detail col-12 col-md-6 col-lg-4">
                    <a href="/admin/paciente-panel/terapias/" className="back">
                        <img src="/img/back/prev-icon.svg" className="img-fluid" />
                        REGRESAR
                    </a>
                    <h1>{therapy.name}</h1>
                    <div className="content">
                        <p>{therapy.description}</p>
                    </div>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setModalIsOpen(true);
                    }} className="btn-green">Solicitar</a>
                </div>
                <div className="text-center col-12 col-md-6 col-lg-8" >
                    <Slider {...settings}>
                        {therapy.images && therapy.images.map(i => {
                            return (
                                <img key={`img-${i.id}`} src={`/uploads/${i.image}`} className="img-fluid" />
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <ModalTherapy
                currentTherapy={data.therapy}
                arrayDays={data.arrayDays}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                room={data.room}
                el="therapy-details"
            />
        </div>
    )
}

export default TherapyDetails

if (document.getElementById('therapy-details')) {
    ReactDOM.render(<TherapyDetails />, document.getElementById('therapy-details'));
}
