const baseUrl = "/admin/room";
export const getFutureReservations = async (roomId) => {
    const response = await fetch(`${baseUrl}/get-futures-reservations`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            _token: $(document).find('meta[name="csrf-token"]').attr("content"),
            room_id: roomId,
        }),
    });
    return await response.json();
};

export const getAvailableRooms = async () => {
    const response = await fetch(`${baseUrl}/available-rooms`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return await response.json();
};

export const changeNewRoom = async (residenceID, newRoom) => {
    const response = await fetch(`${baseUrl}/change-new-rooms`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            _token: $(document).find('meta[name="csrf-token"]').attr("content"),
            residence_id: residenceID,
            new_room_id: newRoom,
        }),
    });
    return await response.json();
};

export const changeReservation = async (residenceID, checkIn, checkOut) => {
    const response = await fetch(`/admin/patient/cambiar-reservacion`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            _token: $(document).find('meta[name="csrf-token"]').attr("content"),
            residence_id: residenceID,
            check_in: checkIn,
            check_out: checkOut,
        }),
    });
    return await response.json();
};
