import React, { useState } from "react";
import { changeReservation } from "../../API/CalendarRooms";
import Swal from "sweetalert2";

const ModalChangeRes = ({ patientName, residence, getData }) => {
    console.log(residence);
    const [checkIn, setCheckIn] = useState("");
    const [checkOut, setCheckOut] = useState("");

    const handleChange = async () => {
        if (!checkIn || !checkOut) {
            Swal.fire('',"Debe seleccionar una fecha de entrada y salida", "warning");
            return;
        }
        const response = await changeReservation(
            residence.id,
            checkIn,
            checkOut
        );
        Swal.fire("Listo!", "Reservación actualizada", "success");
        await getData();
    };

    const cancelChange = async () => {
        Swal.close();
    };

    return (
        <div className="wrap-change-stay-date">
            <div className="main-content">
                <div className="head">
                    <label>
                        Seleccione nueva fecha de reserva para {patientName}
                    </label>
                    <button type="button" onClick={cancelChange} className="close"></button>
                </div>
                <div className="body-row">
                    <label for="new-check-in-date">Entrada</label>
                    <label for="new-check-in-date" className="title">
                        {residence.check_in}
                    </label>
                    <input
                        id="new-check-in-date"
                        value={checkIn}
                        className="form-item date-type"
                        required="required"
                        name="check_in"
                        type="date"
                        onChange={(e) => setCheckIn(e.target.value)}
                    />
                    <label for="new-check-out-date">Salida</label>
                    <label for="new-check-out-date" className="title">
                        {residence.check_out}
                    </label>
                    <input
                        id="new-check-out-date"
                        value={checkOut}
                        className="form-item date-type"
                        required="required"
                        name="check_out"
                        type="date"
                        onChange={(e) => setCheckOut(e.target.value)}
                    />
                    <div className="wrap-buttons d-flex justify-content-centerc align-items-center">
                        <button
                            type="button"
                            onClick={cancelChange}
                            className="cancel">
                            Cancelar
                        </button>
                        <button
                            onClick={handleChange}
                            type="button"
                            className="btnInSwal save-change-reservation"
                        >
                            Cambiar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalChangeRes;
