import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/es'; // Importa el archivo de idioma español
import { getFoods, saveFood } from '../resources';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import SingleFood from './SingleFood';

moment.locale('es'); // Establece el idioma a español

const PatientFood = () => {
    const MySwal = withReactContent(Swal);
    const [data, setData] = useState([]);
    const [currentDay, setCurrentDay] = useState({
        day: '',
        title: '',
        value: '',
        breakfast: null,
        breakfast_place_to_eat: null,
        breakfast_comment: null,
        breakfast_food_subs: [],
        lunch: null,
        lunch_place_to_eat: null,
        lunch_comment: null,
        lunch_food_subs: [],
        dinner: null,
        dinner_place_to_eat: null,
        dinner_comment: null,
        dinner_food_subs: [],
        reference_food_id : null,
        reference_id : null,
    });

    useEffect(() => {
        const gFood = async() => {
            const response = await getFoods();
            if (response.success) {
                // Convierte los días a nombres en español y agrega el número del día
                const daysWithFormattedTitles = response.days.map(day => ({
                    ...day,
                    title: moment(day.value, 'YYYY-MM-DD').format('dddd D'), // Ajusta el formato según tu fecha
                }));
                setData({ ...response, days: daysWithFormattedTitles });
            }
        };
        gFood();
    }, []);

    useEffect(() => {
        if ( data.days ) {
            if(currentDay.value == '') {
                setCurrentDay(data.days[0]);
            } else {
                let day = data.days.find(d => d.day === currentDay.day);
                setCurrentDay(day);
            }
        }
    }, [data]);

    useEffect(() => {
        if (data.foods) {
            // Filtrar y extraer los food_subs de acuerdo a cada categoría
            const breakfastSubs = data.foods
                .filter(food => food.category === 1)
                .flatMap(food => food.food_sub); // Extrae todos los elementos de food_sub en un solo array

            const lunchSubs = data.foods
                .filter(food => food.category === 2)
                .flatMap(food => food.food_sub);

            const dinnerSubs = data.foods
                .filter(food => food.category === 3)
                .flatMap(food => food.food_sub);

            setCurrentDay(prevDay => {
                return {  // Se añade el return aquí para que el estado sea actualizado
                    ...prevDay,
                    breakfast_food_subs: breakfastSubs,
                    lunch_food_subs: lunchSubs,
                    dinner_food_subs: dinnerSubs
                };
            });
        }
    }, [data]);


    // El resto del código sigue igual...
    const getTitle = (cat) => {
        let title = '';
        switch(cat) {
            case 1:
                title = 'Desayuno';
                break;
            case 2:
                title = 'Comida';
                break;
            case 3:
                title = 'Cena';
                break;
        }
        return title;
    };

    const getFood = (cat) => {
        let foodSelected = '';
        switch(cat) {
            case 1:
                foodSelected = currentDay.breakfast.title;
                break;
            case 2:
                foodSelected = currentDay.lunch.title;
                break;
            case 3:
                foodSelected = currentDay.dinner.title;
                break;
        }
        return foodSelected;
    };

    const getFoodSub = (cat) => {
        let currentFoodSub = '';
        switch(cat) {
            case 1:
                currentFoodSub = currentDay.breakfast_sub_food;
                break;
            case 2:
                currentFoodSub = currentDay.lunch_sub_food;
                break;
            case 3:
                currentFoodSub = currentDay.dinner_sub_food;
                break;
        }

        // Verifica si currentFoodSub es null, undefined, o vacío y retorna un valor por defecto si es así
        return currentFoodSub || '';
    };

    const showModal = (e, cat) => {
        e.preventDefault();

        let currentMeal = '';
        const title = getTitle(cat);
        const foodSelectedTitle =  getFood(cat);

        // Define la lista de opciones basadas en `cat`
        let foodSubs = [];
        if (cat === 1) {
            foodSubs = currentDay.breakfast_food_subs;
        } else if (cat === 2) {
            foodSubs = currentDay.lunch_food_subs;
        } else if (cat === 3) {
            foodSubs = currentDay.dinner_food_subs;
        }

        // Mapea `foodSubs` a un array de opciones para el <Select>
        const options = foodSubs && foodSubs.length > 0
            ? foodSubs.map(sub => ({
                label: sub.name,   // Asegúrate de usar la propiedad correcta de `sub`
                value: sub.id      // Asegúrate de usar la propiedad correcta de `sub`
            }))
            : [];

        var currentPlaceToEat = 'comedor';
        const optionsWhereToEat = [
            { value: 'comedor', label: 'Comedor' },
            { value: 'habitación', label: 'Habitación' }
        ];

        if(title === 'Desayuno') {
            currentPlaceToEat = currentDay.breakfast_place_to_eat;
        } else if(title === 'Comida') {
            currentPlaceToEat = currentDay.lunch_place_to_eat;
        } else if(title === 'Cena') {
            currentPlaceToEat = currentDay.dinner_place_to_eat;
        }

        let defaultFoodSub;
        switch (cat) {
            case 1:
                defaultFoodSub = Array.isArray(currentDay.breakfast_food_subs)
                    ? currentDay.breakfast_food_subs.find(food => food.name === currentDay.breakfast_sub_food)
                    : null;
                break;
            case 2:
                defaultFoodSub = Array.isArray(currentDay.lunch_food_subs)
                    ? currentDay.lunch_food_subs.find(food => food.name === currentDay.lunch_sub_food)
                    : null;
                break;
            case 3:
                defaultFoodSub = Array.isArray(currentDay.dinner_food_subs)
                    ? currentDay.dinner_food_subs.find(food => food.name === currentDay.dinner_sub_food)
                    : null;
                break;
            default:
                defaultFoodSub = null;
        }


        MySwal.fire({
            width: 600,
            padding: "0px",
            customClass: {
                popup: 'no-padding-sweet add-item',
            },
            confirmButtonText: 'Editar',
            showCloseButton: true,
            preConfirm: () => {
                if (!currentMeal) {
                  alert('Selecciona una opción');
                  return false; // Evita confirmar si no se ha seleccionado nada
                }
            },
            html: (
                <>
                    <div className="sweetalert-header">
                        <div className="terapia">Seleccionar {title}</div>
                    </div>
                    <div style={{margin: 15}}>
                        <strong>{title}</strong>
                        <label className='subtitle-line'>{foodSelectedTitle}</label>
                    </div>
                    <div style={{margin: 15}}>
                        <strong>Alimento a consumir</strong>
                        <Select
                            menuPlacement='auto'
                            menuPosition="fixed"
                            onChange={(opt) => { currentMeal = opt.value }}
                            options={options}
                            defaultValue={defaultFoodSub ? { label: defaultFoodSub.name, value: defaultFoodSub.name } : null}
                        />
                    </div>
                    <div style={{margin: 15}}>
                        <strong>¿Dónde deseas tomar tus alimentos?</strong>
                        <Select
                            menuPlacement='auto'
                            menuPosition="fixed"
                            defaultValue={optionsWhereToEat.find(option => option.value === currentPlaceToEat)} // Selección por defecto
                            onChange={(opt) => {currentPlaceToEat = opt.value}}
                            options={optionsWhereToEat}
                        />
                    </div>
                </>
            )
        }).then(async (result) => {
            if (result.isConfirmed) {
                await saveFood(currentMeal, currentPlaceToEat, cat, currentDay.day, currentDay.residence_id, currentDay.value);
                const response = await getFoods();
                if (response.success) {
                    setData(response);
                }
            }
        });
    };

    const changeDay = (e, day) => {
        e.preventDefault();
        setCurrentDay(day);
    };

    return (
        <>
            <div className="text-center col-12">
                <div className="nav-terapias">
                    <ul>
                        {data.days && data.days.map(d => (
                            <li key={`day-${d.day}`} className={`${(d.day === currentDay.day) ? 'active' : ''}`}>
                                <a href="#" onClick={(e) => changeDay(e,d)}>{d.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="col-12 bg-gray" style={{width: '100%'}}>
                <div className='row'>
                    <div className='col-10 offset-1'>
                        <div className='row'>
                            <SingleFood title="Desayuno" currentDay={currentDay} food={currentDay.breakfast} foodSub={getFoodSub(1)} showModal={showModal} cat={1} doctorComment={currentDay.breakfast_comment} />
                            <SingleFood title="Comida" currentDay={currentDay} food={currentDay.lunch} foodSub={getFoodSub(2)} showModal={showModal} cat={2} doctorComment={currentDay.lunch_comment} />
                            <SingleFood title="Cena" currentDay={currentDay} food={currentDay.dinner} foodSub={getFoodSub(3)} showModal={showModal} cat={3} doctorComment={currentDay.dinner_comment} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientFood;

if (document.getElementById('patient-foods')) {
    ReactDOM.render(<PatientFood />, document.getElementById('patient-foods'));
}
