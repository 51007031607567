import React, { useMemo, useState } from "react";
import { changeNewRoom } from "../../API/CalendarRooms";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const ModalChangeRoom = ({ patientName, rooms, residence, getData }) => {
    const [roomSelected, setRoomSelected] = useState("");
    const options = useMemo(() => {
        return Object.entries(rooms).map((index) => {
            return (
                <option key={index[0]} value={index[0]}>
                    Habitación {index[1]}
                </option>
            );
        });
    }, [rooms]);

    const handleChangeRoom = async () => {
        const response = await changeNewRoom(residence.id, roomSelected);
        Swal.fire("Listo!", "Nueva habitación asignada", "success");
        await getData();
    };

    const handleClose = () => {
        MySwal.close();
    };

    return (
        <div className="wrap-change-stay-date">
            <div className="main-content">
                <div className="head">
                    <label>
                        Seleccione nueva habitación para {patientName}
                    </label>
                    <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                    ></button>
                </div>
                <div className="body-row">
                    <select
                        id="new-room-selected"
                        className="custom-select"
                        value={roomSelected}
                        onChange={(e) => setRoomSelected(e.target.value)}
                    >
                        {options}
                    </select>
                    <div className="wrap-buttons d-flex justify-content-centerc align-items-center">
                        <button
                            type="button"
                            className="cancel"
                            onClick={handleClose}
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleChangeRoom}
                            type="button"
                            className="btnInSwal save-change-room"
                        >
                            Cambiar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalChangeRoom;
