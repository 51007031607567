import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import slick from 'slick-carousel';
import SingleDay from './SingleDay';

const Calendar = () => {

    const [config, setConfig] = useState([]);
    const {arrayDays:days} = config;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        // Añadir el event listener
        window.addEventListener('resize', handleResize);

        var slidesToShowCal = 3;
        if(typeof all_rooms != 'undefined' && all_rooms) {
            slidesToShowCal = 2;
        }
        if(windowWidth <= 800) {
            slidesToShowCal = 1;
        }

        const getConfig = async () => {
            if(typeof all_rooms != 'undefined' && all_rooms) {
                await fetch(`/admin/enfermera/agenda-extra-config/`)
                    .then( resp => resp.json())
                    .then((data) => {
                        setConfig(data);
                        let initial = getInitialSlide(data.arrayDays);
                        setTimeout(() => {
                            $('.wrap-days').slick({
                                slidesToShow: slidesToShowCal,
                                infinite: false,
                                initialSlide: initial,
                                swipe: true,          // Habilita el deslizamiento (swipe)
                                draggable: true,      // Permite arrastrar el slider
                                touchMove: true,      // Habilita el movimiento táctil
                            });
                        })
                    }).catch(e => {
                        console.log(e);
                    });
            } else {
                await fetch(`/admin/panel-therapies/${internal_room}/config`)
                .then( resp => resp.json())
                .then((data) => {
                    setConfig(data);
                    let initial = getInitialSlide(data.arrayDays);
                    setTimeout(() => {
                        $('.wrap-days').slick({
                            slidesToShow: slidesToShowCal,
                            // arrows: false,
                            infinite: false,
                            initialSlide: initial,
                        });
                    })
                }).catch(e => {
                    console.log(e);
                });
            }

        }

        getConfig();
    }, []);

    const getInitialSlide = (days) => {
        let initial = 0;
        days.map((item, index) => {
            if ( item.today ) {
                initial = index;
            }
        });
        return initial;
    }

    return (
        <div className='wrap-days'>
            {days && days.map((d, index) => {
                return(
                    <SingleDay key={`day-${index}`} day={d} config={config} setConfig={setConfig} />
                )
            })}
        </div>
    )
}

if (document.getElementById('calendar')) {
    ReactDOM.render(<Calendar />, document.getElementById('calendar'));
}
