import React from "react";

const DayHeader = ({day}) => {
    return (
        <div className="header">
            <span>{ (day.today == true) ? 'HOY' : '' }</span>
            {day.date}
        </div>
    )
}

export default DayHeader;