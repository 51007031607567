import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Slider from 'react-slick';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);
import ModalYoutube from './ModalYoutube';


const Conferences = () => {
    const [currentCat, setCurrentCategories] = useState(false);
    const [categories, setCategories] = useState([]);
    const [conferences, setConferences] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        slidesToShow: windowWidth > 1200 ? 3 : windowWidth > 768 ? 2 : 1,
        infinite: false,
    };


    useEffect(() => {
        const getData = async() => {
            await fetch('/admin/api-rest/get-conferences')
            .then(resp => resp.json())
            .then(data => {
                setCategories(data.categories);
                setConferences(data.conferences);
            }).catch(e => {
                console.log(e);
            })
        }
        getData();
    },[]);

    const showConferences = useMemo(() => {
        if(currentCat && conferences) {
            return conferences.filter((t) => {
                if(t.category_id == currentCat ) {
                    return t;
                }
            })
        } else {
            return conferences;
        }
    }, [currentCat, conferences]);


    const handleCat = (e, id) => {
        e.preventDefault();
        setCurrentCategories(id);
    }

    const openModal = (e, v) => {
        e.preventDefault();
        setCurrentVideo(v);
        setModalIsOpen(!modalIsOpen);
    }

    return(
        <>
            <div className="text-center col-12">
                <div className="nav-terapias">
                    <ul>
                        <li className={`${(!currentCat) ? 'active' : ''}`}>
                            <a href="#" onClick={(e) => handleCat(e, null)}>Todas</a>
                        </li>
                        { categories && categories.map(c => {
                            return (
                                <li key={`cat-${c.id}`} className={`${(c.id == currentCat) ? 'active' : ''}`}>
                                    {/* <a href={`?cat=${c.id}`}>{c.name}</a> */}
                                    <a href="#" onClick={(e) => handleCat(e, c.id)}>{c.title}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>

            </div>
            <div className="col-12 bg-gray">
                <Slider {...settings} className='slider'>
                    {showConferences && showConferences.map((t) => {
                        return (
                            <div key={`terapy-${t.id}`} className="single-therapy">
                                <div onClick={(e) => openModal(e, t)} className='bg' style={{backgroundImage: `url('https://img.youtube.com/vi/${t.youtube_id}/0.jpg')`}}>
                                    <div className='bg-black'></div>
                                    <img src='/img/icon-play.webp' />
                                </div>
                                <div className="content-therapy">
                                    <h2>{t.title}</h2>
                                    <p>{t.description}</p>
                                    <div className="buttons">
                                        <a></a>
                                        <a href="#" onClick={(e) => openModal(e, t)} className="btn1">Ver Conferencia</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
            <ModalYoutube
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                currentVideo={currentVideo}
                el="catalog-therapies"
                />
        </>
    );
}

if (document.getElementById('patient-conferences')) {
    ReactDOM.render(<Conferences />, document.getElementById('patient-conferences'));
}
