import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { getDataFormat } from "../../resources";
import { useState } from "react";
import moment from "moment";
import DayStructure from "./DayStructure";
import "moment/locale/es";

const CalendarFormat = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState({
        month: moment().format("MM"),
        year: moment().format("YYYY"),
    });
    const [data, setData] = useState({});

    const handleDate = (e) => {
        const value = e.target.value;
        if (!value) return;
        const year = value.split("-")[0];
        const month = value.split("-")[1];
        setDate({
            month,
            year,
        });
        // console.log(value);
    };

    const getData = async () => {
        setLoading(true);
        const response = await getDataFormat(date.month, date.year);
        setData(response);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, [date]);

    return (
        <div className="mt-5">
            {loading && <p>Cargando...</p>}
            <div className="buttons-format">
                {/* <button>Anterior</button> */}
                <div>
                    <input
                        type="month"
                        id="start"
                        name="start"
                        onChange={handleDate}
                        value={date.year + "-" + date.month}
                    />
                </div>
                {/* <button>Siguiente</button> */}
            </div>
            <div className="table-responsive">
                {!loading && data.days && (
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <td>Habitaciones</td>
                                {Array.from(
                                    { length: data.days },
                                    (_, i) => i + 1
                                ).map((day) => (
                                    <td key={day}>{day}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {data.rooms.map((room) => (
                                <tr key={room.id}>
                                    <td>
                                        <strong>{room.name}</strong>
                                    </td>
                                    {Array.from(
                                        { length: data.days },
                                        (_, i) => i + 1
                                    ).map((day) => (
                                        <DayStructure
                                            key={day}
                                            date={date}
                                            room={room}
                                            day={day}
                                            getData={getData}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default CalendarFormat;

if (document.getElementById("calendarFormat")) {
    ReactDOM.render(
        <CalendarFormat />,
        document.getElementById("calendarFormat")
    );
}
