import $ from "jquery";
window.$ = window.jQuery = $;
require("./bootstrap");
require("./main");
require("./frontDesk");
require("./patient");
require("./calendar");
require("./catalogTherapies");
require("./galleryTherapy");
require("./deleteItem");
require("./deleteImageTherapies");
require("./coupons");
require("./notifications");

require("./components/Calendar");
require("./components/Terapias");
require("./components/PatientFood");
require("./components/TherapyDetails");
require("./components/Conferences");
require("./components/CocinaCalendar");

require("./components/BigCalendar");
require("./components/CalendarFormat/CalendarFormat");
require("./components/ButtonMessageBottom");
