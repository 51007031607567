import ReactDOM from "react-dom";
import React from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useEffect } from "react";
import { getEvents } from "../resources";
import { useState } from "react";
import Swal from "sweetalert2";
import { useCallback } from "react";
import "moment/locale/es";

// moment.locale("es").format("YYYY-MM-DD");
const localizer = momentLocalizer(moment);

const BigCalendar = () => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const today = moment().format("YYYY-MM-DD");

    useEffect(() => {
        getEventsCalendar(today);
    }, []);

    const getEventsCalendar = async (date) => {
        setLoading(true);
        const response = await getEvents(date);
        setEvents(response);
        setLoading(false);
    };

    const handleSelectEvent = useCallback((event) => {
        Swal.fire({
            title: event.title,
            html: `
            <table class="table">
              <tr>
                <td><strong>Fecha Inicio:</strong></td>
                <td>${moment(event.start).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <td><strong>Fecha Fin:</strong></td>
                <td>${moment(event.checkout).format("DD-MM-YYYY")}</td>
              </tr>
            </table>
            `,
        });
    }, []);

    const eventStyleGetter = (event, start, end, isSelected) => {
        console.log(event);
        var backgroundColor = "#" + event.color;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: "0px",
            color: "white",
            border: "0px",
            display: "block",
            padding: "10px 5px",
        };
        return {
            style: style,
        };
    };

    return (
        <div className="row">
            <div className="col-md-12 mt-3">
                {loading && (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                <Calendar
                    localizer={localizer}
                    defaultDate={today}
                    style={{ height: 700 }}
                    startAccessor="start"
                    events={events}
                    onSelectEvent={handleSelectEvent}
                    endAccessor="end"
                    eventPropGetter={eventStyleGetter}
                    onNavigate={(date) => {
                        getEventsCalendar(moment(date).format("YYYY-MM-DD"));
                        console.log(date);
                    }}
                    views={["month", "agenda"]}
                    toolbar={true}
                    culture={"es"}
                />
            </div>
        </div>
    );
};

export default BigCalendar;

if (document.getElementById("bigCalendar")) {
    ReactDOM.render(<BigCalendar />, document.getElementById("bigCalendar"));
}
