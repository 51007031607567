import React, { useState } from 'react';
import DayHeader from './DayHeader';
import Swal from 'sweetalert2';
import { addTherapy, changeStatus, deleteAppointment, getDays, removeTherapy } from '../resources';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);


const SingleDay = ({day, setConfig, config}) => {

    const {arrayDays, therapies, role} = config;

    const isAdmin = () => {
        return (role == 'superuser' || role == 'enfermera');
    }

    const handleExtra = (e, data, therapySelected) => {
        e.preventDefault()
        const {hour, appointment} = data;

        Swal.fire({
            width: 600,
            padding: "0px",
            customClass: {
                popup: 'no-padding-sweet',
            },
            confirmButtonText: 'No hay Disponibilidad',
            showDenyButton: true,
            denyButtonText: `Doctor no la recomienda`,
            showCloseButton: true,
            html: `
            <div class="sweetalert-header">
                <div class="hours">${therapySelected.patient_name} ${therapySelected.room_name} :: ${hour} - ${parseInt(hour)+1}:00</div>
                <div class="terapia">${therapySelected.therapy_name}</div>
            </div>
            `
        }).then( async (result) => {
            let status = false;
            if ( result.isConfirmed ) {
                status = 1;
            } else if ( result.isDenied ) {
                status = 2;
            }
            if ( status ) {
                let res =  await changeStatus(therapySelected.id, status);
                if (res.save) {

                    // Supongamos que el ID del elemento es "myElement"
                    const element = document.getElementById(therapySelected.id);

                    // Verifica si el elemento existe y luego lo elimina
                    if (element) {
                        element.remove();
                    }

                    /*
                    removeItem(data);
                    let resp = await getDays(therapySelected.room_id);
                    setConfig({
                        ...config,
                        'arrayDays': resp.days
                    })
                     */
                } else {
                    alert(res.error);
                }
            }
        });
    }

    // const removeItem = (item) => {
    //     let newArrayDays = [...arrayDays];
    //     newArrayDays.map( ad => {
    //         ad.appointments.map( ap => {
    //             if (ap.appointment && ap.appointment.id ) {
    //                 if ( ap.appointment.id == item.appointment.id) {
    //                     ap.appointment = {};
    //                 }
    //             }
    //         })
    //     });
    //     setConfig({
    //         ...config,
    //         arrayDays: newArrayDays,
    //     })
    // }

    const handleDelete = (e, data) => {
        e.preventDefault();
        const {hour, appointment} = data;
        Swal.fire({
            width: 600,
            padding: "0px",
            customClass: {
                popup: 'no-padding-sweet',
            },
            confirmButtonText: 'Eliminar',
            showDenyButton: true,
            denyButtonText: `Cancelar`,
            showCloseButton: true,
            html: `
            <div class="sweetalert-header">
                <div class="terapia">Deseas eliminar esta cita?</div>
            </div>
            `
        }).then( async (result) => {
            if ( result.isConfirmed ) {
                let res =  await deleteAppointment(appointment.id);
                if (res.save) {
                    let resp = await getDays(config.room.id);
                    setConfig({
                        ...config,
                        'arrayDays': resp.days
                    })
                } else {
                    alert(res.error);
                }
            }
        });
    }

    const handleEdit = (e, day, item) => {
        e.preventDefault();
        const {date, raw_date} = day;
        const {hour, appointment} = item;
        const {therapy} = appointment;
        let currentHour = hour;
        console.log(day, item)
        let therapy_id = therapy.id;
        console.log(hour);
        let selectHoursData = [];
        day.appointments.filter(d => {
            if( !d.food && !d.appointment || (hour == d.hour) ) {
                selectHoursData.push({
                    value: d.hour,
                    label: d.hour,
                });
                return d;
            }
        })
        MySwal.fire({
            width: 600,
            padding: "0px",
            customClass: {
                popup: 'no-padding-sweet add-item',
            },
            confirmButtonText: 'Editar',
            // showDenyButton: true,
            // denyButtonText: `Doctor no la recomienda`,
            showCloseButton: true,
            preConfirm: () => {
                if (!therapy_id) {
                  alert('Selecciona una terapia');
                  return false; // Prevent confirmed
                }
            },
            html: <>
                <div className="sweetalert-header">
                    <div className="terapia">Editar Terapia</div>
                </div>
                <div style={{margin: 15}}>
                    <div className='basic-data'>
                        <div className='cols'>
                            <strong>Dia</strong>
                            <div>{date}</div>
                        </div>
                        <div className='cols'>
                            <strong>Hora</strong>
                            <div className='select-width'>
                                <Select
                                    menuPlacement='auto'
                                    menuPosition="fixed"
                                    onChange={(opt) => {currentHour = opt.value}}
                                    options={selectHoursData}
                                    defaultValue={{ label: hour, value: hour}} />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <strong>Selecciona una terapia</strong>
                        <Select
                            menuPosition="fixed"
                            defaultValue={{ label: therapy.name, value: therapy.id }}
                            onChange={(opt) => {therapy_id = opt.value}}
                            options={therapies} />
                    </div>
                </div>
            </>
        }).then( async (result) => {
            if ( result.isConfirmed ) {
                let res = await addTherapy(config.room.residence.id, therapy_id, raw_date, currentHour, 0);
                if ( hour != currentHour ) {
                    let resp = await removeTherapy(config.room.residence.id, raw_date, hour);
                }
                let resp = await getDays(config.room.id);
                setConfig({
                    ...config,
                    'arrayDays': resp.days
                })
            }
        });
    }

    const handleAdd = (e, day, item) => {
        e.preventDefault();
        const {date, raw_date} = day;
        const {hour} = item;
        let therapy_id = false;
        MySwal.fire({
            width: 600,
            padding: "0px",
            customClass: {
                popup: 'no-padding-sweet add-item',
            },
            confirmButtonText: 'Agregar',
            // showDenyButton: true,
            // denyButtonText: `Doctor no la recomienda`,
            showCloseButton: true,
            preConfirm: () => {
                if (!therapy_id) {
                  alert('Selecciona una terapia');
                  return false; // Prevent confirmed
                }
            },
            html: <>
                <div className="sweetalert-header">
                    <div className="terapia">Agregar Terapia</div>
                </div>
                <div style={{margin: 15}}>
                    <div className='basic-data'>
                        <div className='cols'>
                            <strong>Dia</strong>
                            <div>{date}</div>
                        </div>
                        <div className='cols'>
                            <strong>Hora</strong>
                            <div>{hour}</div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <strong>Selecciona una terapia</strong>
                        <Select menuPosition="fixed"  onChange={(opt) => {therapy_id = opt.value}} options={therapies} />
                    </div>
                </div>
            </>
        }).then( async (result) => {
            if ( result.isConfirmed ) {
                let res = await addTherapy(config.room.residence.id, therapy_id, raw_date, hour, 0);
                let resp = await getDays(config.room.id);
                setConfig({
                    ...config,
                    'arrayDays': resp.days
                })
            }
        });
    }

    const itemExtra = (index, ap) => {
        return (
            <a key={index} href="#" className="item extra">
                {ap.appointment && ap.appointment.map((appointment, idx) => (
                    <React.Fragment key={idx}>
                        <div id={appointment.id} className="wrap-therapy" key={idx} onClick={(e) => handleExtra(e, ap, appointment)}>
                            <div className="title-item">
                                { appointment.therapy_name }
                            </div>
                            <div className="hour-defined">
                                ({appointment.patient_name} {appointment.room_name})
                            </div>
                            <div className="hour-defined">
                                {ap.hour} - {parseInt(ap.hour) + 1}:00
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </a>
        );
    };

    const itemFood = (index, ap) => {
        return (
            <div key={index} className={`item food`}>
                <div className="title-item">{ap.food}</div>
                <div className="hour-defined">{ap.hour} - {parseInt(ap.hour) + 1}:00</div>
            </div>
        );
    }

    const itemAppointment = (index, ap) => {
        return (
            <div key={index} className={`item appointment`}>
                <div className="title-item">{ap.appointment.therapy.name}</div>
                <div className="hour-defined">{ap.hour} - {parseInt(ap.hour) + 1}:00</div>
                <div className='appointment-actions'>
                    <a href='#' onClick={(e) => handleEdit(e, day, ap)}>Editar</a>
                    {isAdmin() &&
                        <a href='#' onClick={(e) => handleDelete(e, ap)}>Eliminar</a>
                    }
                </div>
            </div>
        );
    }

    const itemEmpty = (index, day, ap) => {
        return (
            <div key={index} className={`item empty`}>
                <div className="hour-defined">{ ap.hour } - { parseInt(ap.hour) + 1}:00</div>
                { isAdmin() &&
                    <a href='#' onClick={(e) => handleAdd(e, day, ap)}>Agregar Terapia</a>
                }
            </div>
        );
    }

    return (
        <div className={`single-day ${(day.today)? 'today' : ''}`}>
            <DayHeader day={day} />
            <div className="wrap-programacion">
                {day.appointments && day.appointments.map((ap, index) => {
                    if ( ap.extra == 1 && ap.appointment.length > 0 ) {
                       return itemExtra(index, ap);
                    } else if ( ap.food != '') {
                       return itemFood(index,ap);
                    } else if(ap.appointment && ap.appointment.therapy) {
                        return itemAppointment(index, ap);
                    }
                    return itemEmpty(index, day, ap);
                })}
            </div>
        </div>
    );
}

export default SingleDay;
