import { getAvailableRooms } from "../../API/CalendarRooms";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalChangeRoom from "./ModalChangeRoom";
import ModalChangeRes from "./ModalChangeRes";
const MySwal = withReactContent(Swal);

const ModalChangeReservation = ({ data, room, getData }) => {
    const handleChangeRoom = async () => {
        const data = await getAvailableRooms();
        MySwal.fire({
            html: (
                <ModalChangeRoom
                    residence={room.residence}
                    rooms={data}
                    getData={getData}
                    patientName={room.residence.patient.user.name}
                />
            ),
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "wrap-add-product-service new-room",
        });
    };

    const handleReservationChange = () => {
        MySwal.fire({
            html: (
                <ModalChangeRes
                    residence={room.residence}
                    getData={getData}
                    patientName={room.residence.patient.user.name}
                />
            ),
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: "wrap-add-product-service new-room",
        });
    };

    const handleClose = () => {
        MySwal.close();
    };

    return (
        <div className="wrap-change-stay-date">
            <div className="main-content">
                <div className="head">
                    <label>Próximas visitas para habitación {room.name}</label>
                    <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                    ></button>
                </div>
                {data.dates ? (
                    data.dates.map((date) => (
                        <div className="body" key={date.residence_id}>
                            <button
                                className="btnInSwal btnSmall change-room"
                                // data-patient={date.nickname}
                                // data-residenceid={date.residence_id}
                                // data-currentroom={room.name}
                                onClick={handleChangeRoom}
                            >
                                Cambiar habitación
                            </button>
                            <button
                                className="btnInSwal btnSmall change-reservation"
                                data-checkin={date.check_in}
                                data-checkout={date.check_out}
                                data-patient={date.nickname}
                                data-residenceid={date.residence_id}
                                data-currentroom={room.name}
                                onClick={handleReservationChange}
                            >
                                Cambiar reservación
                            </button>
                            <label>{date.patient_name}</label>
                            <label>{date.future_date}</label>
                        </div>
                    ))
                ) : (
                    <div className="body">
                        <label className="text-center">
                            No existen reservaciones para esta habitación
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalChangeReservation;
