import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Slider from 'react-slick';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);
import ModalTherapy from './ModalTherapy';


const Terapias = () => {
    // const searchParams = new URLSearchParams(document.location.search);
    // const cat = searchParams.get('cat');
    const [currentCat, setCurrentCategories] = useState(false);
    const [categories, setCategories] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [arrayDays, setArrayDays] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentTherapy, setCurrentTherapy] = useState({});
    const [room, setRoom] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        slidesToShow: windowWidth > 1200 ? 3 : windowWidth > 768 ? 2 : 1,
        infinite: false,
    };

    useEffect(() => {

        const getData = async() => {
            await fetch('/admin/api-rest/get-terapias')
            .then(resp => resp.json())
            .then(data => {
                if ( data.days ) {
                    setCategories(data.categories);
                    setTerapias(data.terapies);
                    setArrayDays(data.arrayDays);
                    setRoom(data.room);
                }
            }).catch(e => {
                console.log(e);
            })
        }
        getData();
    },[]);

    const showTerapias = useMemo(() => {
        if(currentCat && terapias) {
            return terapias.filter((t) => {
                if(t.therapy_category_id == currentCat ) {
                    return t;
                }
            })
        } else {
            return terapias;
        }
    }, [currentCat, terapias]);


    const handleCat = (e, id) => {
        e.preventDefault();
        setCurrentCategories(id);
    }

    const handleOpenReservacion = (e, therapy) => {
        e.preventDefault();
        setCurrentTherapy(therapy);
        setModalIsOpen(true);
        // MySwal.fire({
        //     width: 600,
        //     padding: "0px",
        //     customClass: {
        //         popup: 'no-padding-sweet',
        //     },
        //     confirmButtonText: 'Solicitar',
        //     showCloseButton: true,
        //     html: <>
        //     </>,
        // }).then( async (result) => {
        // });
    }

    return(
        <>
            <div className="text-center col-12">
                <div className="nav-terapias">
                    <ul>
                        <li className={`${(!currentCat) ? 'active' : ''}`}>
                            <a href="#" onClick={(e) => handleCat(e, null)}>Todas</a>
                        </li>
                        { categories && categories.map(c => {
                            return (
                                <li key={`cat-${c.id}`} className={`${(c.id == currentCat) ? 'active' : ''}`}>
                                    {/* <a href={`?cat=${c.id}`}>{c.name}</a> */}
                                    <a href="#" onClick={(e) => handleCat(e, c.id)}>{c.name}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>

            </div>
            <div className="col-12 bg-gray">
                <Slider {...settings} className="slider">
                    {showTerapias && showTerapias.map((t) => {
                        return (
                            <div key={`terapy-${t.id}`} className="single-therapy">
                                <figure>
                                    {t.images[0] &&
                                        <img src={`/uploads/${t.images[0].image}`} className="img-fluid" />
                                    }
                                </figure>
                                <div className="content-therapy">
                                    <h2>{t.name}</h2>
                                    <p>{t.description.substring(0,100)}..</p>
                                    <div className="buttons">
                                        <a href={`/admin/paciente-panel/terapias/${t.id}`} className="btn1">Ver Más</a>
                                        <a href="#" onClick={(e) => handleOpenReservacion(e, t)} className="btn2">Solicitar</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
            <ModalTherapy
                currentTherapy={currentTherapy}
                arrayDays={arrayDays}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                room={room}
                el="catalog-therapies"
                />
        </>
    );
}

if (document.getElementById('terapias')) {
    ReactDOM.render(<Terapias />, document.getElementById('terapias'));
}
