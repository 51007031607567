import React from 'react'

const Tabs = ({tab, updateParams}) => {
    const handleTabChange = (e) => {
        e.preventDefault();
        updateParams('tab', e.target.textContent);
    }
    return (
        <div className="tabs-calendar mt-4">
            <ul>
                <li className={(tab == 'Desayuno') ? 'active' : ''}>
                    <a href='#' onClick={handleTabChange}>
                        Desayuno
                    </a>
                </li>
                <li className={(tab == 'Comida') ? 'active' : ''}>
                    <a href='#' onClick={handleTabChange}>
                        Comida
                    </a>
                </li>
                <li className={(tab == 'Cena') ? 'active' : ''}>
                    <a href='#' onClick={handleTabChange}>
                        Cena
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Tabs