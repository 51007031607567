import React from 'react'

const SingleFood = ({currentDay, foodSub, title, food, showModal, cat, doctorComment}) => {
    return (
        <div className='col-12 col-md-6 col-lg-4 single-food'>
            {!food &&
                <p>No esta definido la comida</p>
            }
            {food &&
                <>
                <figure>
                    <img src={`/uploads/${food.image}`} className='img-fluid' />
                </figure>
                <strong>{title}</strong>
                    <h2>{food.title}</h2>
                    <h3>
                        {foodSub}
                    </h3>
                    {doctorComment && (
                        <p>
                            <span>Anotaciones del Doctor: </span>
                            {doctorComment}
                        </p>
                    )}
                </>
            }
            <a href='#' onClick={(e) => showModal(e, cat)}>Cambiar</a>
        </div>
    )
}

export default SingleFood;
