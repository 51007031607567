import 'slick-carousel';
import Swal from 'sweetalert2';

window.Calendar = () => {

    // $('.wrap-days').slick({
    //     slidesToShow: 3,
    //     // arrows: false,
    //     infinite: false,
    // });

    $('.extra').on('click', () => {
        // e.preventDefault();
        console.log($(this).attr('therapy'));
        // let title = $(e.currentTarget).attr('therapy');
        // console.log(title);
        // Swal.fire({
        //     width: 600,
        //     padding: "0px",
        //     customClass: {
        //         popup: 'no-padding-sweet',
        //     },
        //     html: `
        //     <div class="sweetalert-header">
        //         <div class="hours">8:00 - 9:00</div>
        //         <div class="terapia">La TErapia</div>
        //     </div>
        //     `
        // })
    })
};
