const { default: Swal } = require("sweetalert2");

window.Notification = new (function () {
    const that = this;
    this.init = function () {
        console.log('init');
        window.Echo.private(`emergency`)
            .listen('EmergencyNotification', (e) => {
                console.log(e);
                Swal.fire({
                    title: 'Emergencia',
                    text: `La habitacion ${e.room} (${e.name}) tiene una emergencia, por favor acuda a la habitacion`,
                    icon: 'warning',
                })
            });
    }
})();