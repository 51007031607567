
export const getDateDetailsByDate = (date) => {
    const dateSelected = new Date(date);
    const day = dateSelected.toLocaleDateString('es-ES', { weekday: 'long' });
    const month = dateSelected.toLocaleDateString('es-ES', { month: 'long' });
    const monthNumber = dateSelected.getMonth() + 1;
    const monthNumberWithZero = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
    return {
        hour: dateSelected.getHours(),
        todayDate: `${day} ${dateSelected.getDate()} de ${month} del ${dateSelected.getFullYear()}`,
        currentDate: `${dateSelected.getFullYear()}-${monthNumberWithZero}-${dateSelected.getDate()}`,
    }
}
