import React from 'react'

const RoomItem = ({room, tab}) => {
    const { taken, name, residence } = room;

    const isLastDay = () => {
        const today = new Date();
        const currentDay = new Date(room.residence.check_out);
        return today.getDate() == currentDay.getDate();
    }

    const canSeeTheFood = (tab) => {
        if ( tab == 'Desayuno') {
            return true;
        }
        const lastDay = isLastDay();
        if ( !lastDay ) {
            return true;
        }
        if ( tab == 'Cena') {
            return false;
        }
        if ( room.residence.checkout_with_food == 1) {
            return true;
        }
        return false;
    }

    const getNameFood = (tab) => {
        switch (tab) {
            case 'Desayuno':
                return residence.food[0].breakfast.title;
            case 'Comida':
                return residence.food[0].lunch.title;
            case 'Cena':
                return residence.food[0].dinner.title;
            default:
                return '';
        }
    }

    const getNameFoodSub = (tab) => {
        switch (tab) {
            case 'Desayuno':
                return residence.food[0].breakfast_sub_food;
            case 'Comida':
                return residence.food[0].lunch_sub_food;
            case 'Cena':
                return residence.food[0].dinner_sub_food;
            default:
                return '';
        }
    }

    const getCommentFood = (tab) => {
        switch (tab) {
            case 'Desayuno':
                return residence.food[0].comments_breakfast;
            case 'Comida':
                return residence.food[0].comments_lunch;
            case 'Cena':
                return residence.food[0].comments_dinner;
            default:
                return '';
        }
    }

    const getWhereToEatFood = (tab) => {
        switch (tab) {
            case 'Desayuno':
                return (residence.food[0].breakfast_place_to_eat) ? residence.food[0].breakfast_place_to_eat : 'Comedor';
            case 'Comida':
                return (residence.food[0].lunch_place_to_eat) ? residence.food[0].lunch_place_to_eat : 'Comedor';
            case 'Cena':
                return (residence.food[0].dinner_place_to_eat) ? residence.food[0].dinner_place_to_eat : 'Comedor';
            default:
                return '';
        }
    }

    return (
        <div className='col-12 mntl-dash_itemWrap Cocina'>
            <div className={`mntl-dash_item ${(taken == 0) ? 'item_empty' : ''} ${residence && residence.medical_assessment.diabetic == 1 ? 'diabetic' : ''} ${residence && residence.todayColema == 1 ? 'colema' : ''} `}>
                <div className="mntl-dash_item-hdr dFlex align-items-center justify-content-between">
                    <span className="item-number">{name}</span>
                </div>
                {residence && taken == 1 &&
                    <div className='mntl-dash_item-data'>
                            {residence.food && residence.food.length > 0 &&
                                <ul>
                                    {canSeeTheFood(tab) &&
                                        <li>
                                            <span className="item-name big">
                                                {getNameFood(tab)}
                                                <br/>
                                                {getNameFoodSub(tab)
                                                    ? <label>{getNameFoodSub(tab)}</label>
                                                    : <label></label>}
                                            </span>
                                            <span className="item-comment where-to-eat">
                                                <i>{getWhereToEatFood(tab)}</i>
                                            </span>
                                            <span className="item-comment">
                                                {getCommentFood(tab)}
                                            </span>
                                        </li>
                                    }
                                </ul>
                            }
                        <div className='name-patient'>
                            <span className='name'>

                                {residence.patient && residence.patient.user && residence.patient.user.nickname
                                    ? residence.patient.user.nickname
                                    : 'Nombre no disponible'}

                            </span>
                            <figure>
                                {residence.patient && residence.patient.full_image && (
                                    <img
                                        src={`${residence.patient.full_image}`}
                                        alt="Paciente"
                                        className='img-fluid'
                                    />
                                )}
                            </figure>
                        </div>
                </div>
                }
            </div>
        </div>
    )
}

export default RoomItem
