const prefix = "/admin/panel-therapies";
const csrfToken = document.head.querySelector(
    "[name~=csrf-token][content]"
).content;

export const changeStatus = async (id, status) => {
    return await fetch(`${prefix}/change-status`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
            id,
            status,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const deleteAppointment = async (id) => {
    return await fetch(`${prefix}/delete-appointment`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
            id,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const removeTherapy = async (residence_id, date, hour) => {
    return await fetch(`${prefix}/remove-therapy`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
            residence_id,
            date,
            hour,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const addTherapy = async (
    residence_id,
    therapy_id,
    date,
    hour,
    extra = 0
) => {
    return await fetch(`${prefix}/add-therapy`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
            residence_id,
            therapy_id,
            date,
            hour,
            extra,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const getDays = async (room_id) => {
    return await fetch(`${prefix}/${room_id}/get-days`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const getTherapy = async (therapy_id) => {
    return await fetch(`${prefix}/${therapy_id}/therapy`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const getFoods = async (residenceId) => {
    return await fetch(`${prefix}/foods`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const saveFood = async (food_id, place_to_eat, cat, day, residence_id, date) => {
    return await fetch(`${prefix}/save-food`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
            residence_id,
            food_id,
            place_to_eat,
            cat,
            day,
            date,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                save: false,
                error: "Hubo un error al momento de guardar",
            };
        });
};

export const getRooms = async (date, part) => {
    return await fetch(`/admin/cocina/rooms/${date}/${part}`, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            return {
                success: true,
                error: "Hubo un error al obtener las habitaciones",
            };
        });
};

export const getEvents = async (date) => {
    return await fetch(`/admin/calendar/get-events/${date}`, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((e) => {
            console.log(e);
            return [];
        });
};

export const getDataFormat = async (month, year) => {
    return await fetch(
        `/admin/calendar/get-data-format?month=${month}&year=${year}`,
        {
            method: "GET",
        }
    )
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((e) => {
            console.log(e);
            return [];
        });
};
